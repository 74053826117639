@import "styles/design-system";

.nb-page-user-settings-container {
  @include nb-shape;

  background-color: nb-color(base, white);
  width: 100%;
  height: 100%;
  padding: nb-rem(50px);
}
