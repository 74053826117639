@import "styles/design-system";

.nb-common-note {
  @include nb-shape;

  background-color: nb-color(secondary, purple-300);
  padding: nb-rem(20px);
}

.nb-common-note-title,
.nb-common-note-content {
  @include nb-color-primary-grey-900;
}

.nb-common-note-icon {
  svg[fill],
  path[fill] {
    fill: nb-color(primary, grey-900);
  }

  svg[stroke],
  path[stroke] {
    stroke: nb-color(primary, grey-900);
  }
}
