@import "styles/design-system";

.nb-interactive-date-picker-container {
  position: relative;
}

.nb-interactive-date-picker-opener-input {
  margin-bottom: 0;
}

.nb-interactive-date-picker-holder {
  /* Positioning */
  position: fixed;
  z-index: 150;

  /* Display */
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition:
    visibility nb-easing(linear) nb-duration(none) nb-delay(hurry),
    opacity nb-duration(base) nb-delay(base),
    transform nb-duration(base) nb-delay(base);

  &--open {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition:
      visibility nb-easing(linear) nb-duration(none) nb-delay(base),
      opacity nb-duration(base) nb-delay(base),
      transform nb-duration(base) nb-delay(base);
  }
}

/* Main container for date picker */
.nb-interactive-date-picker {
  @include nb-shape;
  @include nb-depth(200);

  /* Other */
  background-color: nb-color(base, white);
  padding: nb-rem(7px) nb-rem(10px) nb-rem(10px);
  width: nb-rem(197px);
  min-width: nb-rem(197px);

  /* IMPORTANT: Down below are library classes we need to override to bring our own design for date picker */

  /* Calendar navigation container at the top of date picker (month switcher) */
  .react-calendar__navigation {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  /* Calendar navigation (button that switches views of date picker).
  We don't use this functionality, so we resetting button styles */
  .react-calendar__navigation__label {
    appearance: none;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    font-size: nb-rem(10px);
    line-height: nb-rem(12px);
    cursor: default;
    user-select: none;
    pointer-events: none;
  }

  /* Calendar navigation button text (Exp: December 2022) */
  .react-calendar__navigation__label__labelText {
    @include nb-text-style-body1;
    @include nb-color-base-dark-900;

    font-size: nb-rem(10px);
    line-height: nb-rem(12px);
    text-transform: capitalize;
  }

  /* Calendar navigation button with icon (Month button switcher) */
  .react-calendar__navigation__arrow {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    margin: 0;
    width: nb-rem(15px);
    max-width: nb-rem(15px);
    min-width: nb-rem(15px);
    height: nb-rem(15px);
    max-height: nb-rem(15px);
    min-height: nb-rem(15px);
    background: nb-color(base, white);
    outline-color: nb-color(primary, orange-900);
    box-shadow: 0 7px 20px rgb(0 0 0 / 7%);
    border-radius: nb-rem(3.7px);
    cursor: pointer;
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);

    path {
      stroke: nb-color(base, dark-900);
    }

    &:hover,
    &:focus {
      background: nb-color(primary, orange-700);
    }

    &:focus-visible {
      outline-offset: nb-rem(3px);
      outline: nb-rem(1px) solid nb-color(primary, orange-700);
    }

    &:active {
      transition: none;
      background: nb-color(primary, orange-300);
    }

    &:disabled {
      background: nb-color(base, white);
      opacity: 0.25;
      cursor: default;
    }
  }

  .react-calendar__navigation__prev-button {
    svg {
      transform: rotate(90deg);
    }
  }

  .react-calendar__navigation__next-button {
    svg {
      transform: rotate(270deg);
    }
  }

  /* Calendar weekdays wrapper (dashed underline) */
  .react-calendar__month-view__weekdays {
    position: relative;
    margin-top: nb-rem(5px);
    margin-bottom: nb-rem(15px);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-bottom: dashed nb-rem(1px) nb-color(base, dark-100);
    }
  }

  /* Calendar weekday labels (Sunday, Monday, etc) */
  .react-calendar__month-view__weekdays__weekday {
    padding: nb-rem(5px);

    abbr {
      @include nb-text-style-body2(bold);

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      text-transform: lowercase;
      text-decoration: none;
      color: nb-color(primary, orange-900);
      font-size: nb-rem(10px);
      line-height: nb-rem(12px);
    }
  }

  /* Calendar date tiles with various states (main class).
  Other tiles are modifications of this one. */
  .nb-interactive-date-picker-tile {
    @include nb-text-style-body2;

    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    min-height: nb-rem(22px);
    font-size: nb-rem(10px);
    line-height: nb-rem(12px);
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    user-select: none;
    margin-bottom: nb-rem(5px);
    z-index: 1;

    &::after {
      @include nb-shape(circle);

      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.3);
      width: nb-rem(20px);
      height: nb-rem(20px);
      background-color: nb-color(base, white);
      z-index: -1;
      transition: all nb-easing(in) nb-duration(base) nb-delay(base);
      outline-color: nb-color(primary, orange-900);
    }

    abbr {
      @include nb-color-base-dark-900;

      font-size: nb-rem(10px);
      line-height: nb-rem(12px);
      transition: all nb-easing(in) nb-duration(base) nb-delay(base);
    }

    &:hover,
    &:focus {
      abbr {
        @include nb-color-primary-orange-900;
      }

      &::after {
        background-color: nb-color(primary, orange-100);
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:focus-visible {
      outline: none;

      &::after {
        outline: nb-rem(1px) solid nb-color(primary, orange-700);
      }
    }

    &:active {
      abbr {
        @include nb-color-primary-orange-100;

        transition: none;
      }

      &::after {
        transition: none;
        opacity: 0.5;
      }
    }

    &:disabled {
      cursor: not-allowed;

      abbr {
        @include nb-color-base-dark-100;
      }

      &::after {
        transition: none;
        background-color: nb-color(base, white);
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  /* Tile of neighbor month days */
  .react-calendar__month-view__days__day--neighboringMonth abbr {
    @include nb-color-base-dark-100;
  }

  /* Current day */
  .react-calendar__tile--now {
    abbr {
      @include nb-color-primary-orange-900;
    }

    &::after {
      transform: translate(-50%, -50%) scale(1);
      background: nb-color(primary, orange-100);
    }
  }

  /* Selected day or range of dates */
  .react-calendar__tile--active,
  .react-calendar__tile--range,
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--hasActive {
    abbr {
      @include nb-color-base-white;
    }

    &::after {
      @include nb-shape(circle);

      transform: translate(-50%, -50%) scale(1);
      background: nb-color(primary, orange-900);
    }

    &:hover,
    &:focus {
      abbr {
        @include nb-color-base-white;
      }

      &::after {
        transform: translate(-50%, -50%) scale(1);
        background: nb-color(primary, orange-900);
      }
    }
  }

  .react-calendar__tile--active {
    &::after {
      border-radius: 0;
    }
  }

  .react-calendar__tile--rangeEnd {
    &::after {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }

  .react-calendar__tile--rangeStart {
    &::after {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }

  /* Hover state while selecting range of dates */
  .react-calendar__tile--hover,
  .react-calendar__tile--hoverStart,
  .react-calendar__tile--hoverEnd {
    abbr {
      @include nb-color-primary-orange-900;
    }

    &::after {
      border-radius: 0;
      transform: translate(-50%, -50%) scale(1);
      background: nb-color(primary, orange-100);
    }
  }

  .react-calendar__tile--hoverStart {
    &::after {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }

  .react-calendar__tile--hoverEnd {
    &::after {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}
