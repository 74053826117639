@import "styles/design-system";

.nb-interactive-search .nb-interactive-search-control {
  border-width: nb-rem(1px);
  border-style: solid;
  border-color: transparent;
  box-shadow: none;
  min-height: nb-rem(40px);
  cursor: pointer;

  &:has(.nb-interactive-search-icon) {
    padding-left: nb-rem(10px);
  }

  &:hover {
    border-color: transparent;
    box-shadow: none;
  }

  &--focused {
    border-color: transparent;
    box-shadow: none;
  }

  &--open {
    box-shadow: none;
    border-radius: nb-rem(6px) nb-rem(6px) 0 0;
  }

  &--menu-hidden {
    border-radius: nb-rem(6px);
  }

  &--bordered {
    border-color: nb-color(secondary, grey-300);

    &:hover {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
    }
  }
}

.nb-interactive-search-icon {
  width: nb-rem(15px);
  height: nb-rem(15px);

  path {
    stroke: nb-color(primary, grey-900);
  }
}
