@import "styles/design-system";

.nb-interactive-image-cropper-container,
.nb-interactive-image-upload-preview {
  position: relative;
  width: 100%;
}

.nb-interactive-image-upload-preview {
  font-size: 0;
  line-height: 0;

  img {
    @include nb-shape;

    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.nb-interactive-image-upload-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;

  path {
    stroke: nb-color(base, white);
    opacity: 1;
  }

  &::after {
    @include nb-shape;

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: nb-color(base, dark-900);
    opacity: 0.85;
    z-index: -1;
  }
}
