@import "styles/design-system";

.nb-interactive-column-item-name-wrapper {
  position: relative;
}

.nb-interactive-column-item-input {
  margin-bottom: 0;
  flex-grow: 1;

  &--name {
    margin-right: nb-rem(50px);
  }
}

.nb-interactive-column-item-delete {
  position: absolute;
  right: 0;
}
