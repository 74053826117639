@import "styles/design-system";

.nb-interactive-tabs-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  position: relative;
  margin-bottom: nb-rem(10px);

  /* Giving space for animated underline */
  padding-bottom: nb-rem(4px);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: nb-rem(1px);
    background-color: nb-color(secondary, grey-300);
  }
}

.nb-interactive-tabs-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nb-interactive-tabs-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  width: auto;
}

.nb-interactive-tabs-button {
  @include nb-text-style-body2;
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: nb-color(secondary, grey-900);
  padding: nb-rem(7px) nb-rem(5px);
  margin: 0;
  appearance: none;
  border: none;
  background: none;
  box-shadow: none;
  user-select: none;
  cursor: pointer;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  outline-color: nb-color(primary, orange-900);

  &--active {
    color: nb-color(primary, orange-900);
  }

  &:hover {
    color: nb-color(primary, orange-900);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    color: nb-color(primary, orange-300);
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    color: nb-color(secondary, grey-300);
  }
}

.nb-interactive-tabs-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: nb-rem(4px);
  background-color: nb-color(primary, orange-900);
  border-top-left-radius: nb-rem(10px);
  border-top-right-radius: nb-rem(10px);
  transition: all nb-easing(base) nb-duration(base) nb-delay(base);
  transform-origin: 0 0;
}
