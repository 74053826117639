@import "styles/design-system";

.nb-breadcrumbs-route-current,
.nb-breadcrumbs-emoji {
  cursor: default;
}

.nb-breadcrumbs-emoji {
  margin-right: nb-rem(10px);
}

.nb-breadcrumbs-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.nb-breadcrumbs-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nb-breadcrumbs-route-link {
  @include nb-text-style-body1(base, ptmono);
  @include nb-shape;

  text-decoration: none;
  color: nb-color(base, dark-300);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &:hover {
    color: nb-color(base, dark-900);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
    color: nb-color(base, dark-300);
  }

  &:active {
    transition: none;
    color: nb-color(base, dark-300);
  }
}

.nb-breadcrumbs-item-separator {
  @include nb-text-style-body1(base, ptmono);

  color: nb-color(base, dark-300);
  margin: 0 nb-rem(8px);
}
