@import "styles/design-system";

.nb-common-select-custom-option {
  padding: nb-rem(15px);
  padding-right: 0;
}

.nb-common-select-custom-option-icon {
  width: 75px;
  height: 57px;
  flex-shrink: 0;
}

.nb-common-select-custom-option-description {
  color: nb-color(secondary, grey-700);
}
