@import "styles/design-system";

.nb-interactive-person-select {
  .nb-interactive-person-select-multi-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .nb-interactive-person-select-value-label {
    @include nb-shape;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: nb-rem(5px);
    margin: nb-rem(10px) nb-rem(10px) 0 0;
    background: nb-color(secondary, purple-300);
  }

  .nb-interactive-person-select-value-label-text {
    font-size: nb-rem(12px);
  }

  .nb-interactive-person-select-remove-button {
    display: inline-block;
    padding: nb-rem(2px);
    margin: 0 0 0 nb-rem(10px);
    appearance: none;
    background: none;
    border: none;
    font-size: 0;
    line-height: 0;
    cursor: pointer;

    svg {
      width: nb-rem(15px);
      height: nb-rem(15px);

      path {
        stroke: nb-color(primary, grey-900);
      }
    }
  }
}
