@import "styles/design-system";

.nb-interactive-person-select {
  .nb-interactive-person-select-control {
    border-width: nb-rem(1px);
    border-style: solid;
    border-color: nb-color(secondary, grey-300);
    box-shadow: none;
    min-height: nb-rem(40px);
    cursor: pointer;

    &:has(.nb-interactive-person-select-control-icon) {
      padding-left: nb-rem(10px);
    }

    &:hover {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
    }
  }

  .nb-interactive-person-select-control-icon {
    width: nb-rem(15px);
    height: nb-rem(15px);

    path {
      stroke: nb-color(primary, grey-900);
    }
  }
}
