@import "styles/design-system";

.nb-interactive-textarea-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.nb-interactive-textarea-label-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(10px);

  &--only-hint {
    min-height: nb-rem(25px);
  }
}

.nb-interactive-textarea-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  &--with-hint {
    padding-right: nb-rem(40px);
  }
}

.nb-interactive-textarea-hint-container {
  position: absolute;
  top: 0;
  right: 0;
}

.nb-interactive-textarea-holder {
  position: relative;
  min-width: nb-rem(196px);

  &--full {
    width: 100%;
  }
}

.nb-interactive-textarea {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
  @include nb-shape;

  display: flex;
  background: none;
  outline: none;
  filter: none;
  resize: none;
  margin: 0;
  padding: nb-rem(8px) nb-rem(10px);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  border: nb-border-weight(thin) nb-color(secondary, grey-300) solid;
  width: 100%;
  min-height: nb-rem(122px);

  &--success {
    border-color: nb-color(secondary, green-900);
  }

  &--error {
    border-color: nb-color(secondary, error-900);
  }

  &--resizable {
    resize: vertical;
  }

  &::placeholder {
    @include nb-text-style-body2;
    @include nb-color-secondary-grey-900;
  }

  &:focus,
  &:focus-visible {
    border-color: nb-color(secondary, blue-300);
  }

  &:disabled {
    @include nb-color-secondary-grey-900;

    cursor: default;
    border-color: nb-color(secondary, grey-300);
    opacity: 0.4;
  }

  /* Hack to remove autofill browser styles */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:disabled,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 5000s;
  }
}

.nb-interactive-textarea-button {
  position: absolute;
  bottom: nb-rem(15px);
  right: nb-rem(15px);
  min-width: nb-rem(150px);
  background: nb-color(primary, orange-900);
  color: nb-color(base, white);

  &:hover:not(:disabled) {
    background: nb-color(primary, orange-700);
  }

  &:active:not(:disabled) {
    background: nb-color(primary, orange-700);
  }
}

.nb-interactive-textarea-error-text {
  @include nb-text-style-body2;
  @include nb-color-secondary-error-900;

  margin-top: nb-rem(10px);
}
