@import "styles/design-system";

$nb-common-space-size-xx-small: 5px;
$nb-common-space-size-x-small: 10px;
$nb-common-space-size-small: 15px;
$nb-common-space-size-medium: 20px;
$nb-common-space-size-large: 25px;
$nb-common-space-size-x-large: 30px;
$nb-common-space-size-xx-large: 35px;
$nb-common-space-size-xxx-large: 40px;

.nb-common-space {
  display: inline-flex;
  flex-wrap: nowrap;
  width: auto;
  box-sizing: border-box;
  gap: 0;

  // Width prop
  &--full-width {
    display: flex;
    width: 100%;
  }

  // Wrap prop
  &--wrap {
    flex-wrap: wrap;
  }

  // Direction prop
  &--direction-horizontal {
    flex-direction: row;
  }

  &--direction-vertical {
    flex-direction: column;
  }

  // Justify prop
  &--justify-stretch {
    justify-content: stretch;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-baseline {
    justify-content: baseline;
  }

  &--justify-between {
    justify-content: space-between;
  }

  // Align prop
  &--align-stretch {
    align-items: stretch;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--align-baseline {
    align-items: baseline;
  }

  // Size prop
  &--size-row-none {
    row-gap: 0;
  }

  &--size-column-none {
    column-gap: 0;
  }

  &--size-row-xx-small {
    row-gap: $nb-common-space-size-xx-small;
  }

  &--size-column-xx-small {
    column-gap: $nb-common-space-size-xx-small;
  }

  &--size-row-x-small {
    row-gap: $nb-common-space-size-x-small;
  }

  &--size-column-x-small {
    column-gap: $nb-common-space-size-x-small;
  }

  &--size-row-small {
    row-gap: $nb-common-space-size-small;
  }

  &--size-column-small {
    column-gap: $nb-common-space-size-small;
  }

  &--size-row-medium {
    row-gap: $nb-common-space-size-medium;
  }

  &--size-column-medium {
    column-gap: $nb-common-space-size-medium;
  }

  &--size-row-large {
    row-gap: $nb-common-space-size-large;
  }

  &--size-column-large {
    column-gap: $nb-common-space-size-large;
  }

  &--size-row-x-large {
    row-gap: $nb-common-space-size-x-large;
  }

  &--size-column-x-large {
    column-gap: $nb-common-space-size-x-large;
  }

  &--size-row-xx-large {
    row-gap: $nb-common-space-size-xx-large;
  }

  &--size-column-xx-large {
    column-gap: $nb-common-space-size-xx-large;
  }

  &--size-row-xxx-large {
    row-gap: $nb-common-space-size-xxx-large;
  }

  &--size-column-xxx-large {
    column-gap: $nb-common-space-size-xxx-large;
  }
}
