@import "styles/design-system";

.nb-site-navigation {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.nb-site-navigation-list {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
}

.nb-site-navigation-list-item {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin: 0;
  margin-bottom: nb-rem(9px);
  padding: 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}
