@import "styles/design-system";

.nb-page-study-groups-action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .nb-page-study-groups-action-link {
    max-width: nb-rem(153px);
    min-width: nb-rem(153px);
    width: nb-rem(153px);
    margin-right: nb-rem(10px);
  }
}
