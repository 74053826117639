@import "styles/design-system";

.nb-interactive-answer-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: nb-rem(10px);

  &:not(:last-of-type) {
    margin-bottom: nb-rem(10px);
  }
}

.nb-interactive-answer-input-field {
  flex-grow: 1;
  margin-bottom: 0;
}
