@import "styles/design-system";

.nb-interactive-input-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: nb-rem(10px);
}

.nb-interactive-input-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-bottom: nb-rem(10px);
}

.nb-interactive-input-holder {
  position: relative;
  min-width: nb-rem(196px);

  &--full {
    width: 100%;
  }
}

.nb-interactive-input-icon {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &--left {
    right: unset;
    left: nb-rem(10px);
  }

  &--right {
    left: unset;
    right: nb-rem(10px);
  }

  &--fade {
    opacity: 0.4;
  }

  svg {
    width: nb-rem(15px);
    height: auto;
  }

  path {
    stroke: nb-color(base, black);
  }
}

.nb-interactive-input {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
  @include nb-shape;

  display: flex;
  background: none;
  outline: none;
  filter: none;
  margin: 0;
  padding: nb-rem(8px) nb-rem(10px);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  border: nb-border-weight(thin) nb-color(secondary, grey-300) solid;
  width: 100%;

  &--success {
    border-color: nb-color(secondary, green-900);
  }

  &--error {
    border-color: nb-color(secondary, error-900);
  }

  &--prefix {
    padding-left: nb-rem(35px);
  }

  &--suffix {
    padding-right: nb-rem(35px);
  }

  &::placeholder {
    @include nb-text-style-body2;
    @include nb-color-secondary-grey-900;
  }

  &:focus,
  &:focus-visible {
    border-color: nb-color(secondary, blue-300);
  }

  &:disabled {
    @include nb-color-secondary-grey-900;

    cursor: default;
    border-color: nb-color(secondary, grey-300);
    opacity: 0.4;
  }

  &:read-only {
    cursor: default;

    &:hover,
    &:focus,
    &:focus-visible {
      border-color: nb-color(secondary, grey-300);
    }
  }

  /* Hack to remove autofill browser styles */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:disabled,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 5000s;
  }

  /* Hide Arrows From Input Number */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
  }
}

.nb-interactive-input-error-text {
  @include nb-text-style-body2;
  @include nb-color-secondary-error-900;

  margin-top: nb-rem(10px);
}
