@import "styles/design-system";

.nb-interactive-clue-select-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.nb-interactive-clue-select-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-bottom: nb-rem(10px);
}

.nb-interactive-clue-select {
  min-width: nb-rem(89px);
  max-width: nb-rem(196px);

  &--full {
    width: 100%;
  }
}

.nb-interactive-clue-select-tooltip-container {
  align-self: auto;
  line-height: 0;
}

.nb-interactive-clue-select-tooltip {
  padding: nb-rem(4px) nb-rem(8px);
}
