@import "styles/design-system";

.nb-interactive-image-upload-container {
  min-width: nb-rem(443px);
  width: nb-rem(443px);
}

.nb-interactive-image-upload-modal {
  z-index: 95;
}
