@import "styles/design-system";

.nb-interactive-button {
  @include nb-text-style-button;
  @include nb-color-base-white;
  @include nb-shape;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  border: none;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  cursor: pointer;
  user-select: none;
  appearance: button;
  margin: 0;
  padding: nb-rem(7.25px) nb-rem(10px);
  background: nb-color(primary, orange-900);
  box-sizing: border-box;
  outline-color: nb-color(primary, orange-900);

  &:hover {
    background: nb-color(primary, orange-700);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    background: nb-color(primary, orange-300);
  }

  &:disabled {
    cursor: default;
    background: nb-color(secondary, grey-300);
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }

  &--full {
    width: 100%;
  }
}

.nb-interactive-button-secondary {
  @include nb-color-primary-orange-900;

  border: nb-border-weight(thin) nb-color(primary, orange-900) solid;
  background: transparent;
  padding: nb-rem(6.25px) nb-rem(10px);

  &:hover {
    @include nb-color-primary-orange-700;

    border: nb-border-weight(thin) nb-color(primary, orange-700) solid;
    background: transparent;
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
    border: nb-border-weight(thin) nb-color(primary, orange-900) solid;
  }

  &:active {
    @include nb-color-primary-orange-700;

    border: nb-border-weight(thin) nb-color(primary, orange-700) solid;
    background: transparent;
  }

  &:disabled {
    @include nb-color-secondary-grey-300;

    cursor: default;
    border: nb-border-weight(thin) nb-color(secondary, grey-300) solid;
    background: transparent;
  }
}

.nb-interactive-button-tertiary {
  @include nb-color-base-dark-900;

  border: nb-border-weight(thin) transparent solid;
  background: transparent;
  padding: nb-rem(6.25px) nb-rem(10px);

  &:hover {
    @include nb-color-primary-orange-700;

    background: nb-color(secondary, grey-100);

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(primary, orange-700);
      }
    }
  }

  &:focus-visible {
    @include nb-color-primary-orange-700;

    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
    background: nb-color(secondary, grey-100);

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(primary, orange-700);
      }
    }
  }

  &:active {
    @include nb-color-primary-orange-700;

    background: nb-color(secondary, grey-100);
    opacity: 0.5;

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(primary, orange-700);
      }
    }
  }

  &:disabled {
    @include nb-color-secondary-grey-300;

    background: nb-color(secondary, grey-100);
    opacity: 0.5;

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(secondary, grey-300);
      }
    }
  }
}

.nb-interactive-button-text {
  @include nb-color-base-dark-900;

  border: nb-border-weight(thin) transparent solid;
  background: transparent;
  padding: nb-rem(6.25px) nb-rem(5px);
  font-weight: nb-font-weight();

  &:hover {
    @include nb-color-primary-orange-700;

    background: transparent;

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(primary, orange-700);
      }
    }
  }

  &:focus-visible {
    @include nb-color-primary-orange-700;

    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
    background: transparent;

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(primary, orange-700);
      }
    }
  }

  &:active {
    @include nb-color-primary-orange-500;

    background: transparent;

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(primary, orange-500);
      }
    }
  }

  &:disabled {
    @include nb-color-secondary-grey-300;

    background: transparent;
    opacity: 0.5;

    &:has(.nb-interactive-button-icon) {
      path {
        stroke: nb-color(secondary, grey-300);
      }
    }
  }
}

.nb-interactive-button-ghost {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  appearance: none;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  opacity: 0.4;
  min-width: unset;
  width: auto;

  &:hover {
    @include nb-color-base-dark-700;

    appearance: none;
    border: none;
    background: none;
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(base, dark-900);
  }

  &:active {
    @include nb-color-base-dark-300;

    transition: none;
    background: none;
  }

  &:disabled {
    cursor: default;
    background: none;
    opacity: 0.2;
  }
}

.nb-interactive-button-link {
  text-decoration: none;

  &--disabled {
    cursor: default;
    background: nb-color(secondary, grey-300);
    pointer-events: none;
  }
}

.nb-interactive-button-icon {
  width: nb-rem(15px);
  height: nb-rem(15px);

  &--left {
    margin-right: nb-rem(10px);
  }

  &--right {
    margin-left: nb-rem(10px);
  }

  path {
    stroke: nb-color(base, white);
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  }

  &--secondary {
    path {
      stroke: nb-color(primary, orange-900);
    }
  }

  &--tertiary {
    path {
      stroke: nb-color(base, dark-900);
    }
  }

  &--text {
    path {
      stroke: nb-color(base, dark-900);
    }
  }
}
