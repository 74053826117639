@import "styles/design-system";

.nb-typography-paragraph-body-1 {
  @include nb-text-style-body1;
  @include nb-color-base-dark-900;

  padding: 0;
  margin: 0;

  &--bold {
    @include nb-text-style-body1(bold);
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-paragraph-body-2 {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  padding: 0;
  margin: 0;

  &--bold {
    @include nb-text-style-body2(bold);
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-paragraph-line-break {
  white-space: pre-wrap;
}
