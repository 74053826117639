@import "styles/design-system";

.nb-page-component-login-card {
  @include nb-shape;

  display: flex;
  flex-flow: column nowrap;
  background-color: nb-color(base, white);
  padding: nb-rem(60px);
  min-width: nb-rem(433px);
  width: nb-rem(433px);
}

.nb-page-component-login-card-title {
  font-size: nb-rem(30px);
  margin-bottom: nb-rem(20px);
}

.nb-page-component-login-card-text {
  margin-bottom: nb-rem(20px);
  white-space: pre-line;
}
