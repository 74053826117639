@import "styles/design-system";

.nb-page-component-request-reset-password-card {
  @include nb-shape;

  display: flex;
  flex-flow: column nowrap;
  background-color: nb-color(base, white);
  padding: nb-rem(30px) nb-rem(60px) nb-rem(60px) nb-rem(60px);
  min-width: nb-rem(433px);
  width: nb-rem(433px);
}

.nb-page-component-request-reset-password-card-title {
  font-size: nb-rem(30px);
  margin-bottom: nb-rem(20px);
}

.nb-page-component-request-reset-password-card-text {
  margin-bottom: nb-rem(20px);
  white-space: pre-line;
}

.nb-page-component-request-reset-password-back-button {
  @include nb-text-style-body2;
  @include nb-color-primary-grey-300;
  @include nb-shape;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  cursor: pointer;
  user-select: none;
  appearance: none;
  margin: 0;
  margin-bottom: nb-rem(20px);
  text-decoration: none;
  padding: 0;
  align-self: flex-start;
  border: nb-border-weight(thin) transparent solid;
  background: transparent;
  box-sizing: border-box;
  outline-color: nb-color(primary, orange-900);

  &:hover {
    @include nb-color-primary-orange-700;
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    @include nb-color-primary-orange-300;

    transition: none;
  }

  &:disabled {
    cursor: default;

    @include nb-color-primary-orange-300;
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}
