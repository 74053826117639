@import "styles/design-system";

.nb-exercise-button-self {
  min-width: nb-rem(196px);
  align-self: center;
}

.nb-exercise-container {
  overflow: hidden;
  height: auto;
  transition: height 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &--collapsed {
    height: 0;
  }
}
