@import "styles/design-system";

.nb-exercise-constructor-move-words-into-gaps {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: nb-rem(20px);
}

.nb-exercise-constructor-move-words-into-gaps-text textarea {
  @include nb-scrollbar($bg-color: transparent);
}
