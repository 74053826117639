@import "styles/design-system";

$nb-interactive-icon-button-size-small: 25px;
$nb-interactive-icon-button-size-medium: 40px;
$nb-interactive-icon-button-size-large: 50px;

.nb-interactive-icon-button-primary,
.nb-interactive-icon-button-secondary,
.nb-interactive-icon-button-tertiary {
  @include nb-shape;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  cursor: pointer;
  user-select: none;
  appearance: button;
  margin: 0;
  padding: 0;
  border: nb-border-weight(thin) nb-color(secondary, grey-200) solid;
  background: nb-color(base, white);
  box-sizing: border-box;
  outline-color: nb-color(primary, orange-900);
  max-height: var(--nb-interactive-icon-button-size);
  min-height: var(--nb-interactive-icon-button-size);
  height: var(--nb-interactive-icon-button-size);
  max-width: var(--nb-interactive-icon-button-size);
  min-width: var(--nb-interactive-icon-button-size);
  width: var(--nb-interactive-icon-button-size);

  &:hover {
    background: nb-color(primary, orange-700);
    border: nb-border-weight(thin) nb-color(primary, orange-700) solid;
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    background: nb-color(primary, orange-700);
    border: nb-border-weight(thin) nb-color(primary, orange-700) solid;
  }

  &:disabled {
    cursor: default;
    background: nb-color(base, white);
    opacity: 0.4;
    pointer-events: none;
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }

  &--svg {
    width: nb-rem(15px);
    height: nb-rem(15px);

    path {
      stroke: nb-color(base, dark-900);
    }
  }

  &--small {
    --nb-interactive-icon-button-size: #{$nb-interactive-icon-button-size-small};
  }

  &--medium {
    --nb-interactive-icon-button-size: #{$nb-interactive-icon-button-size-medium};
  }

  &--large {
    --nb-interactive-icon-button-size: #{$nb-interactive-icon-button-size-large};
  }
}

.nb-interactive-icon-button-secondary {
  background: nb-color(base, white);

  &:hover,
  &:active,
  &:focus-visible {
    background: nb-color(base, white);
  }
}

.nb-interactive-icon-button-tertiary {
  background: transparent;
  border: none;

  &:hover,
  &:active,
  &:focus-visible {
    background: transparent;
    border: none;
  }

  &:disabled {
    background: transparent;
    opacity: 1;

    .nb-interactive-icon-button-tertiary--svg path {
      stroke: nb-color(secondary, error-300);
    }
  }
}
