@import "styles/design-system";

.nb-sub-classroom-empty-wrapper {
  height: 100%;
}

.nb-sub-classroom-empty-button-wrapper,
.nb-sub-classroom-empty-button {
  position: relative;
  z-index: 1;
}

.nb-sub-classroom-empty-button-image {
  display: block;
  position: absolute;
  bottom: 0;
  left: nb-rem(128px);
  z-index: 0;
}

.nb-sub-classroom-empty-message {
  white-space: pre-line;
  text-align: center;
}
