@import "styles/design-system";

.nb-lesson-details-header {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: nb-rem(10px);
  padding-left: nb-rem(20px);
  margin-bottom: nb-rem(20px);
  background-color: nb-color(secondary, grey-200);
}

.nb-lesson-details-preview-switch {
  background-color: nb-color(secondary, grey-500);
}
