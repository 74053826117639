@import "styles/design-system";

.nb-sub-detailed-duration-wrapper {
  @include nb-shape;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  background-color: nb-color(secondary, grey-100);
  padding: nb-rem(20px);
  margin-bottom: nb-rem(20px);
}

.nb-sub-detailed-duration-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(15px);
}

.nb-sub-detailed-duration-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  margin-left: nb-rem(15px);
}

.nb-sub-detailed-duration-content-secondary {
  opacity: 0.5;
}

.nb-sub-detailed-duration-select {
  margin-bottom: 0;
}
