@import "styles/design-system";

.nb-sub-materials-details-image {
  width: nb-rem(171px);
  height: nb-rem(134px);
  margin-right: nb-rem(15px);
}

.nb-sub-materials-details-poster {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  border: nb-border-weight(thin) nb-color(primary, grey-100) solid;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nb-sub-materials-details-emoji {
  font-size: nb-rem(20px);
  line-height: nb-rem(28px);
}

.nb-sub-materials-details-picture {
  object-fit: contain;
}
