@import "styles/design-system";

.nb-sub-materials-list {
  @include nb-shape;

  /* Full width background */
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: flex-start;
  // align-items: stretch;
  // gap: nb-rem(20px);
  // width: 100%;

  /* Adaptive background */
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, nb-rem(171px));
  justify-content: flex-start;
  align-items: flex-start;
  gap: nb-rem(20px);
  max-width: 100%;
  background: nb-color(base, white);
  padding: nb-rem(20px);
  margin: 0;
  list-style: none;
}
