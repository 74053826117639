@import "styles/design-system";

$nb-interactive-switch-width: 48px;
$nb-interactive-switch-height: 28px;
$nb-interactive-switch-padding: 2px;
$nb-interactive-switch-handle-size: calc($nb-interactive-switch-width / 2);

// For icons and text inside switch
$nb-interactive-switch-inner-padding-min: 8px;
$nb-interactive-switch-inner-padding-max: $nb-interactive-switch-handle-size + 2 * $nb-interactive-switch-padding;
$nb-interactive-switch-inner-margin-min: $nb-interactive-switch-handle-size - $nb-interactive-switch-padding;
$nb-interactive-switch-inner-margin-max: $nb-interactive-switch-width;

.nb-interactive-switch {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
  @include nb-shape(large);

  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  min-width: nb-rem($nb-interactive-switch-width);
  height: nb-rem($nb-interactive-switch-height);
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  user-select: none;
  cursor: pointer;
  background-color: nb-color(secondary, grey-100);
  outline-color: nb-color(primary, orange-900);
  transition: all nb-duration(base) nb-delay(base);

  &:hover {
    background-color: nb-color(secondary, grey-300);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    .nb-interactive-switch-handle::before {
      inset-inline-end: -20%;
      inset-inline-start: 0;
    }

    .nb-interactive-switch-inner-checked--checked {
      margin-inline-start: -30%;
      margin-inline-end: 30%;
    }

    &:not(.nb-interactive-switch--checked) .nb-interactive-switch-inner-unchecked {
      margin-inline-start: 30%;
      margin-inline-end: -30%;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--checked:active {
    .nb-interactive-switch-handle::before {
      inset-inline-end: 0;
      inset-inline-start: -20%;
    }
  }
}

.nb-interactive-switch-wave-container {
  position: absolute;
  top: 0;
  left: 0;
}

.nb-interactive-switch-wave {
  @include nb-shape(large);

  position: absolute;
  top: 0;
  left: 0;
  width: nb-rem($nb-interactive-switch-width);
  height: nb-rem($nb-interactive-switch-height);
  background: 0 0;
  pointer-events: none;
  box-sizing: border-box;
  box-shadow: 0 0 0 0 nb-color(primary, orange-900);
  opacity: 0.2;
  transition:
    box-shadow 0.4s cubic-bezier(0.08, 0.82, 0.17, 1),
    opacity 2s cubic-bezier(0.08, 0.82, 0.17, 1);

  &--show {
    box-shadow: 0 0 0 6px nb-color(primary, orange-900);
    opacity: 0;
  }
}

.nb-interactive-switch-handle {
  position: absolute;
  top: nb-rem($nb-interactive-switch-padding);
  inset-inline-start: nb-rem($nb-interactive-switch-padding);
  width: nb-rem($nb-interactive-switch-handle-size);
  height: nb-rem($nb-interactive-switch-handle-size);
  transition: all nb-duration(base) nb-delay(base) ease-in-out;

  &::before {
    @include nb-shape(large);

    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    background-color: nb-color(base, white);
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    transition: all nb-duration(base) nb-delay(base) ease-in-out;
  }

  &--checked {
    inset-inline-start: calc(100% - nb-rem($nb-interactive-switch-handle-size + $nb-interactive-switch-padding));

    &::before {
      background-color: nb-color(primary, orange-900);
    }
  }
}

.nb-interactive-switch-inner {
  @include nb-shape(large);

  display: block;
  overflow: hidden;
  height: 100%;
  padding-inline-start: nb-rem($nb-interactive-switch-inner-padding-max);
  padding-inline-end: nb-rem($nb-interactive-switch-inner-padding-min);
  transition:
    padding-inline-start nb-duration(base) nb-delay(base) ease-in-out,
    padding-inline-end nb-duration(base) nb-delay(base) ease-in-out;

  &--checked {
    padding-inline-start: nb-rem($nb-interactive-switch-inner-padding-min);
    padding-inline-end: nb-rem($nb-interactive-switch-inner-padding-max);
  }
}

.nb-interactive-switch-inner-checked,
.nb-interactive-switch-inner-unchecked {
  @include nb-text-style-body2;

  display: block;
  pointer-events: none;
  line-height: nb-rem($nb-interactive-switch-height);
  transition:
    margin-inline-start nb-duration(base) nb-delay(base) ease-in-out,
    margin-inline-end nb-duration(base) nb-delay(base) ease-in-out;
}

.nb-interactive-switch-inner-checked {
  margin-inline-start: calc(-100% + $nb-interactive-switch-inner-margin-min - $nb-interactive-switch-inner-margin-max);
  margin-inline-end: calc(100% - $nb-interactive-switch-inner-margin-min + $nb-interactive-switch-inner-margin-max);

  &--checked {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
}

.nb-interactive-switch-inner-unchecked {
  margin-top: nb-rem(-$nb-interactive-switch-height);
  margin-inline-start: 0;
  margin-inline-end: 0;

  &--checked {
    margin-inline-start: calc(100% - $nb-interactive-switch-inner-margin-min + $nb-interactive-switch-inner-margin-max);
    margin-inline-end: calc(-100% + $nb-interactive-switch-inner-margin-min - $nb-interactive-switch-inner-margin-max);
  }
}
