@import "styles/design-system";

.nb-interactive-question-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  margin-bottom: nb-rem(20px);
  gap: nb-rem(10px);
}

.nb-interactive-question-input-field {
  flex-grow: 1;
  margin-bottom: 0;
}
