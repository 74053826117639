@import "styles/design-system";

.nb-layout-site-content {
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  margin-left: nb-rem(224px);
  background-color: nb-color(secondary, grey-100);
  overflow-x: hidden;
}
