@import "styles/design-system";

.nb-interactive-avatar-upload-opener {
  appearance: none;
  border: none;
  width: auto;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 1px solid transparent;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &--rect {
    @include nb-shape;
  }

  &--round {
    @include nb-shape(circle);
  }

  &:hover {
    outline: 1px solid nb-color(primary, orange-700);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    outline: 1px solid nb-color(primary, orange-300);
  }

  &:disabled {
    cursor: default;
    outline: 1px solid nb-color(secondary, grey-300);
  }
}

.nb-interactive-avatar-upload-avatar {
  &--rect {
    @include nb-shape;
  }

  &--round {
    @include nb-shape(circle);
  }
}
