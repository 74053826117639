@import "styles/design-system";

.nb-interactive-file-upload-cropper {
  position: relative;
  display: block;
  width: 100%;
  height: 190px;
}

.nb-interactive-file-upload-cropper-loader {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: nb-rem(15px);
  width: 100%;
  height: 100%;
  background-color: nb-color(secondary, grey-300);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image:
      linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
    animation: nb-cropper-loader-shimmer 2s infinite;
  }
}

.nb-interactive-file-upload-cropper-loader-text {
  color: nb-color(base, dark-300);
}

@keyframes nb-cropper-loader-shimmer {
  100% {
    transform: translateX(100%);
  }
}
