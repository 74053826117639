@import "styles/design-system";

.nb-interactive-select .nb-interactive-select-value {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin: 0;

  &--element {
    white-space: normal;
    text-overflow: clip;
  }
}
