@import "styles/design-system";

.nb-interactive-person-select .nb-interactive-person-select-option {
  @include nb-text-style-body2;
  @include nb-color-secondary-grey-900;
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: nb-color(secondary, grey-100);
  padding: nb-rem(20px);

  &:not(:last-of-type) {
    margin-bottom: nb-rem(10px);
  }

  &:hover {
    background: nb-color(secondary, purple-300);
  }

  &--focused {
    background: nb-color(secondary, purple-300);
  }

  &--searched {
    background: nb-color(secondary, purple-300);
  }

  .nb-interactive-person-select-option-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: nb-rem(15px);
  }

  .nb-interactive-person-select-option-text {
    color: nb-color(base, dark-500);
  }
}
