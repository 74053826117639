@import "styles/design-system";

.nb-exercise-constructor-config-content {
  @include nb-scrollbar;

  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - nb-rem(91px));
  padding: nb-rem(30px) calc(nb-rem(30px) - $nb-scrollbar-width) nb-rem(30px) nb-rem(30px);
}

.nb-exercise-constructor-config-action {
  padding: nb-rem(30px);
  padding-top: 0;
}
