@import "styles/design-system";

.nb-interactive-select .nb-interactive-select-clear-indicator {
  padding: 0;

  .nb-interactive-select-clear-indicator-icon {
    width: nb-rem(15px);
    height: nb-rem(15px);

    path {
      stroke: nb-color(primary, grey-900);
    }
  }
}
