@import "styles/design-system";

.nb-courses-materials-pagination-previous {
  svg {
    transform: rotate(90deg);
  }
}

.nb-courses-materials-pagination-next {
  svg {
    transform: rotate(270deg);
  }
}
