@import "styles/design-system";

.nb-layout-site-header {
  // For fixed header add following:
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 20;
  // margin-left: nb-rem(224px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-height: nb-rem(60px);
  height: nb-rem(60px);
  width: 100%;
  padding: nb-rem(10px) nb-rem(20px);
  background-color: nb-color(base, white);
}

.nb-layout-site-header-controls {
  display: flex;
  flex-flow: row nowrap;

  .nb-layout-site-header-controls-notifications {
    margin-right: nb-rem(8px);
  }
}
