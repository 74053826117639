.attempt-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-left: 2px;

  &__circle {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #e0e0e0;
  }

  &__circle--success {
    background-color: #4caf50;
  }

  &__circle--error {
    background-color: #f44336;
  }
}
