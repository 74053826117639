@import "styles/design-system";

.nb-interactive-clue-select .nb-interactive-clue-select-menu {
  margin-top: nb-rem(10px);
  border-width: nb-rem(1px);
  border-style: solid;
  border-color: nb-color(secondary, grey-300);
  border-radius: nb-rem(20px);
  box-shadow: none;
  background: nb-color(base, white);
  z-index: 5;
}
