@import "styles/design-system";

.nb-interactive-true-or-false-assertion-select {
  .nb-interactive-true-or-false-assertion-select-dropdown {
    @include nb-shape;

    padding: nb-rem(5px);
  }

  .nb-interactive-true-or-false-assertion-select-icon-chevron {
    width: nb-rem(15px);
    height: nb-rem(15px);
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);

    &--focused {
      transform: rotate(180deg);
    }

    path {
      stroke: nb-color(primary, grey-900);
    }
  }
}
