@import "styles/design-system";

.nb-study-requests-table-status-select {
  margin-bottom: 0;

  .nb-interactive-select {
    width: 100%;
    min-width: nb-rem(150px);
  }

  &--new {
    .nb-interactive-select-control {
      background-color: #fcd3cc;
      border-color: #e62b0d;

      &:hover {
        border-color: #e62b0d;
      }

      &--focused {
        border-color: #e62b0d;
      }
    }

    .nb-interactive-select-value {
      color: #e62b0d;
    }

    .nb-interactive-select-icon-chevron path {
      stroke: #e62b0d;
    }
  }

  &--processed {
    .nb-interactive-select-control {
      background-color: #eef9f0;
      border-color: #339044;

      &:hover {
        border-color: #339044;
      }

      &--focused {
        border-color: #339044;
      }
    }

    .nb-interactive-select-value {
      color: #339044;
    }

    .nb-interactive-select-icon-chevron path {
      stroke: #339044;
    }
  }
}
