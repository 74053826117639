@import "styles/design-system";

.nb-exercise-make-words-from-letters-draggable {
  @include nb-shape;

  position: relative;
  transition: transform nb-duration(base) nb-easing(base) nb-delay(base);
  box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, grey-300);

  .nb-exercise-make-words-from-letters-draggable-button {
    @include nb-text-style-heading2;
    @include nb-color-base-dark-900;
    @include nb-shape;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    min-height: nb-rem(60px);
    min-width: nb-rem(60px);
    padding: nb-rem(10px);
    margin: 0;
    text-transform: uppercase;
    appearance: none;
    outline: none;
    border: none;
    cursor: grab;
    box-sizing: border-box;
    background-color: transparent;
    outline-color: nb-color(primary, orange-900);
    box-shadow: var(--nb-draggable-box-shadow);
    transform:
      translate3d(
        var(--nb-move-words-from-letters-draggable-translate-x, 0),
        var(--nb-move-words-from-letters-draggable-translate-y, 0),
        0
      )
      scale(var(--nb-draggable-scale, 1));
    transition:
      box-shadow nb-duration(slow) nb-easing(base) nb-delay(base),
      transform nb-duration(slow) nb-easing(base) nb-delay(base),
      outline-offset nb-duration(slow) nb-easing(base) nb-delay(base),
      outline-color nb-duration(slow) nb-easing(base) nb-delay(base),
      background-color nb-duration(slow) nb-easing(base) nb-delay(base);

    &:hover:not(:disabled) {
      outline: solid 0 transparent;
      background: nb-color(secondary, purple-300);
    }

    &:focus-visible:not(:disabled) {
      outline: solid nb-rem(1px) nb-color(primary, orange-900);
      outline-offset: nb-rem(5px);
    }

    &:active:not(:disabled) {
      transition: none;
      outline: solid 0 transparent;
      background: nb-color(secondary, purple-300);
    }

    &:disabled {
      cursor: default;
      opacity: 0.7;
    }

    &--correct {
      background: nb-color(secondary, green-100);
      outline: solid nb-rem(1px) nb-color(secondary, green-500);
      outline-offset: nb-rem(-1px);
      cursor: default;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background: nb-color(secondary, green-100);
        outline: solid nb-rem(1px) nb-color(secondary, green-500);
        outline-offset: nb-rem(-1px);
      }
    }

    &--fail {
      background: nb-color(secondary, error-100);
      outline: solid nb-rem(1px) nb-color(secondary, error-900);
      outline-offset: nb-rem(-1px);
      cursor: default;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background: nb-color(secondary, error-100);
        outline: solid nb-rem(1px) nb-color(secondary, error-900);
        outline-offset: nb-rem(-1px);
      }
    }
  }

  &--dragging {
    z-index: 1;
    transition: none;

    .nb-exercise-make-words-from-letters-draggable-button {
      --nb-draggable-scale: 1.06;
      --nb-draggable-box-shadow: #{nb-elevation(300)};

      cursor: grabbing;
    }
  }

  &--overlay,
  &--dragging {
    .nb-exercise-make-words-from-letters-draggable-button {
      transition:
        box-shadow nb-duration(slow) nb-easing(base) nb-delay(base),
        transform nb-duration(slow) nb-easing(base) nb-delay(base),
        outline-offset nb-duration(slow) nb-easing(base) nb-delay(base),
        background-color nb-duration(none) nb-easing(base) nb-delay(base);
      animation: nb-make-words-from-letters-draggable-animate-pop nb-duration(base) nb-easing(excite) nb-delay(base);
    }
  }
}

@keyframes nb-make-words-from-letters-draggable-animate-pop {
  0% {
    transform:
      translate3d(
        var(--nb-move-words-from-letters-draggable-translate-x, 0),
        var(--nb-move-words-from-letters-draggable-translate-y, 0),
        0
      )
      scale(1);
  }

  100% {
    transform:
      translate3d(
        var(--nb-move-words-from-letters-draggable-translate-x, 0),
        var(--nb-move-words-from-letters-draggable-translate-y, 0),
        0
      )
      scale(var(--nb-draggable-scale));
    box-shadow: var(--nb-draggable-box-shadow);
  }
}
