@import "styles/design-system";

.nb-common-input-label-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &--only-hint {
    min-height: nb-rem(25px);
  }
}

.nb-common-input-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  &--with-hint {
    padding-right: nb-rem(40px);
  }
}

.nb-common-input-label-hint-container {
  position: absolute;
  top: 0;
  right: 0;
}
