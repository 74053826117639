@import "styles/design-system";

.nb-exercise-sort-words-droppable-item {
  flex: 0 0 calc(50% - nb-rem(10px));
}

.nb-exercise-sort-words-droppable-item-head {
  width: 100%;
  background-color: nb-color(secondary, grey-200);
  border-top-right-radius: nb-shape(small);
  border-top-left-radius: nb-shape(small);
  padding: nb-rem(5px) nb-rem(20px);
  text-align: center;
  transition: background-color nb-duration(slow) nb-easing(base) nb-delay(base);

  &--dragging {
    background-color: nb-color(secondary, purple-300);
  }

  &--over {
    background-color: nb-color(secondary, purple-300);
  }
}

.nb-exercise-sort-words-droppable-item-area {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: nb-rem(10px);
  width: 100%;
  box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, grey-200);
  border-bottom-right-radius: nb-shape(small);
  border-bottom-left-radius: nb-shape(small);
  padding: nb-rem(10px);
  min-height: nb-rem(123px);
  transition: box-shadow nb-duration(slow) nb-easing(base) nb-delay(base);

  &--empty {
    align-items: center;
  }

  &--dragging {
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, purple-300);
  }

  &--over {
    box-shadow: inset 0 0 0 nb-rem(3px) nb-color(secondary, purple-300);
  }
}

.nb-exercise-sort-words-droppable-item-area-empty {
  width: 100%;
  color: nb-color(secondary, grey-700);
  text-align: center;
}
