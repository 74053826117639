@import "styles/design-system";

.nb-interactive-upload-input-wrapper {
  position: relative;
  display: inline-flex;
  width: auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  &--full {
    width: 100%;
  }
}

.nb-interactive-upload-input {
  display: none;
}

.nb-interactive-upload-input-button {
  @include nb-shape;

  position: relative;
  display: inline-block;
  width: 100%;
  min-height: nb-rem(143px);
  appearance: none;
  border: none;
  outline: none;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  margin: 0;
  padding: nb-rem(10px);
  background-color: nb-color(secondary, brown-100);
  outline-color: nb-color(primary, orange-900);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border: nb-border-weight(thin) nb-color(secondary, brown-300) dashed;
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  }

  &--drag-active,
  &:hover {
    background-color: nb-color(secondary, brown-300);

    &::after {
      border-color: nb-color(secondary, brown-500);
    }

    .nb-interactive-upload-input-button-text {
      color: nb-color(secondary, brown-500);
    }
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    opacity: 0.5;
  }

  &:disabled {
    cursor: default;
    background: nb-color(secondary, grey-300);
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

.nb-interactive-upload-input-button-text {
  color: nb-color(secondary, brown-300);
  padding: nb-rem(10px);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
}

.nb-interactive-upload-input-drag-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
