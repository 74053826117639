@import "styles/design-system";

.nb-courses-materials-cover-item {
  width: nb-rem(171px);
}

.nb-courses-materials-cover-item-button {
  @include nb-shape;

  display: inline-block;
  text-decoration: none;
  outline-color: nb-color(primary, orange-900);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-align: left;

  &:hover {
    .nb-courses-materials-cover-item-poster {
      border-color: nb-color(primary, grey-300);
    }
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    opacity: 0.5;
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

.nb-courses-materials-cover-item-poster {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  border: nb-border-weight(thin) nb-color(primary, grey-100) solid;
  margin-bottom: nb-rem(15px);
  width: 100%;
  height: nb-rem(219px);
  overflow: hidden;
}

.nb-courses-materials-cover-item-image {
  object-fit: cover;
  flex-shrink: 0;
}

.nb-courses-materials-cover-item-emoji {
  font-size: nb-rem(20px);
  line-height: nb-rem(28px);
}
