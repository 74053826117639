@import "styles/design-system";

.nb-sub-materials-details-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.nb-sub-materials-details-heading {
  margin-bottom: nb-rem(10px);

  &--emoji {
    margin-right: nb-rem(5px);
  }
}

.nb-sub-materials-details-paragraph {
  opacity: 0.5;
  max-width: nb-rem(300px);
}
