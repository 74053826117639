@import "styles/design-system";

.nb-interactive-profile-dropdown-button {
  @include nb-shape;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  cursor: pointer;
  user-select: none;
  appearance: button;
  margin: 0;
  padding: 0;
  border: nb-border-weight(thin) transparent solid;
  background: nb-color(base, white);
  box-sizing: border-box;
  outline-color: nb-color(primary, orange-900);
  max-height: nb-rem(40px);
  min-height: nb-rem(40px);
  height: nb-rem(40px);
  max-width: nb-rem(40px);
  min-width: nb-rem(40px);
  width: nb-rem(40px);
  overflow: hidden;

  &:hover {
    border: nb-border-weight(thin) nb-color(primary, orange-700) solid;
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    opacity: 0.7;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }

  img {
    display: block;
  }
}
