@import "styles/design-system";

.nb-sub-students-classroom-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.nb-sub-students-classroom-list {
  @include nb-shape;

  display: inline-grid;
  grid-template-columns: repeat(auto-fit, nb-rem(171px));
  justify-content: flex-start;
  align-items: stretch;
  gap: nb-rem(20px);
  max-width: 100%;
  background: nb-color(base, white);
  padding: nb-rem(20px);
  margin: 0;
  list-style: none;
}
