@import "styles/design-system";

.nb-interactive-select {
  .nb-interactive-select-control {
    border-width: nb-rem(1px);
    border-style: solid;
    border-color: nb-color(secondary, grey-300);
    box-shadow: none;
    min-height: nb-rem(40px);
    cursor: pointer;

    &:has(.nb-interactive-select-icon) {
      padding-left: nb-rem(10px);
    }

    &:hover {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
    }

    &--focused {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
    }

    &--open {
      box-shadow: none;
      border-radius: nb-rem(6px) nb-rem(6px) 0 0;
    }

    &--disabled {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
      background-color: nb-color(secondary, grey-300);
      opacity: 0.7;
      cursor: not-allowed;
    }

    &--readonly {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
      background-color: nb-color(base, white);
      cursor: default;
      opacity: 1;
    }
  }

  .nb-interactive-select-icon {
    width: nb-rem(15px);
    height: nb-rem(15px);

    path {
      stroke: nb-color(primary, grey-900);
    }
  }
}
