@import "styles/design-system";

.nb-sub-schedule-controls-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(20px);
}

.nb-sub-schedule-controls-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.nb-schedule-controls-header {
  display: flex;
  align-items: center;
  gap: nb-rem(10px);
}

.nb-sub-schedule-controls-title {
  margin-right: nb-rem(15px);
  margin-top: nb-rem(5px);
  margin-bottom: nb-rem(5px);
}

.nb-sub-schedule-controls-select {
  margin-bottom: 0;
  margin-right: nb-rem(15px);
}
