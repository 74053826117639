@import "styles/design-system";

.nb-sub-study-student-schedule-modal {
  min-width: nb-rem(1236px);
  max-width: nb-rem(1236px);
  width: nb-rem(1236px);
}

.nb-sub-study-student-schedule-modal-content {
  @include nb-scrollbar;

  padding: 0 calc(nb-rem(20px) - $nb-scrollbar-width) nb-rem(20px) nb-rem(20px);
  overflow-y: auto;
  max-height: 80vh;
}
