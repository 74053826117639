@import "styles/design-system";

.nb-lesson-details-chapter-name {
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: nb-rem(5px);
  min-width: 0;

}
