@import "styles/design-system";

.nb-interactive-person-select .nb-interactive-person-select-menu-list {
  @include nb-scrollbar;

  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;

  &--1 {
    max-height: nb-rem(90px);
  }

  &--2 {
    max-height: nb-rem(190px);
  }

  &--3 {
    max-height: nb-rem(290px);
  }

  &--4 {
    max-height: nb-rem(390px);
  }

  &--5 {
    max-height: nb-rem(490px);
  }
}
