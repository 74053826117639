@import "styles/design-system";

.nb-page-component-edit-event-student,
.nb-page-component-edit-event-group {
  margin-bottom: nb-rem(20px);
}

.nb-page-component-edit-event-delete {
  margin-bottom: nb-rem(10px);
}

.nb-page-component-edit-event-date-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.nb-page-component-edit-event-date-picker {
  .nb-interactive-input-holder {
    min-width: unset;
    max-width: nb-rem(160px);
  }
}

.nb-page-component-edit-event-time {
  margin-bottom: 0;
  margin-top: auto;

  .nb-interactive-select {
    width: nb-rem(160px);
    max-width: nb-rem(160px);
    min-width: nb-rem(160px);
  }
}

.nb-page-component-edit-event-status {
  margin-top: nb-rem(20px);
  margin-bottom: 0;
}
