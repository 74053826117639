@import "styles/design-system";

.nb-common-avatar-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: transparent;

  &--small {
    height: nb-rem(50px);
  }

  &--medium {
    height: nb-rem(60px);
  }

  &--large {
    height: nb-rem(79px);
  }
}

.nb-common-avatar-list-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.nb-common-avatar-list-item {
  &--overlap {
    margin-left: nb-rem(-20px);
  }
}

.nb-common-avatar-list-label {
  color: nb-color(secondary, grey-900);
  margin-left: nb-rem(5px);
}
