@import "../design-system";

:root {
  font-family: $nb-theme-font-family;
  font-size: $nb-text-base-font-size;
}

html {
  font-family: $nb-theme-font-family;
  font-size: $nb-text-base-font-size;
  background-color: nb-color(secondary, grey-100);
  color: nb-color(base, dark-900);
  margin: nb-space(none);
  padding: nb-space(none);
  font-weight: nb-font-weight(regular);
}
