@import "styles/design-system";

.nb-exercise-constructor-modal {
  position: fixed;
  z-index: 90;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  opacity: 0;
  visibility: hidden;
  transition:
    visibility nb-easing(linear) nb-duration(none) nb-delay(hurry),
    opacity nb-duration(base) nb-delay(base),
    transform nb-duration(base) nb-delay(base);

  &--open {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition:
      visibility nb-easing(linear) nb-duration(none) nb-delay(base),
      opacity nb-duration(base) nb-delay(base),
      transform nb-duration(base) nb-delay(base);
  }
}

.nb-exercise-constructor-modal-content {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100vh;
  width: min-content;
  transform: translateX(-100%);
  transition: transform nb-duration(base) nb-delay(base);

  &--open {
    transform: translateX(0);
    transition: transform nb-duration(base) nb-delay(base);
  }
}
