@import "styles/design-system";

.nb-interactive-radio-group-input-container {
  position: relative;
}

.nb-interactive-radio-group-input-tick {
  @include nb-shape(circle);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: nb-rem(24px);
  height: nb-rem(24px);
  background-color: nb-color(base, white);
  border: nb-border-weight(thin) nb-color(base, dark-100) solid;
  transition: all nb-easing(in) nb-duration(slow) nb-delay(base);
  outline-color: nb-color(primary, orange-900);

  &--checked {
    background-color: nb-color(secondary, green-500);
    border-color: nb-color(base, white);
  }
}

.nb-interactive-radio-group-input-tick-icon {
  width: nb-rem(10px);
  height: nb-rem(7px);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition:
    visibility nb-easing(linear) nb-duration(none) nb-delay(hurry),
    opacity nb-duration(base) nb-delay(base),
    transform nb-duration(base) nb-delay(base);

  path {
    stroke: nb-color(base, white);
    fill: nb-color(base, white);
  }

  &--checked {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition:
      visibility nb-easing(linear) nb-duration(none) nb-delay(base),
      opacity nb-duration(base) nb-delay(base),
      transform nb-duration(base) nb-delay(base);
  }
}

.nb-interactive-radio-group-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 23;
  width: nb-rem(24px);
  height: nb-rem(24px);
  border: none;
  box-shadow: none;
  background: none;
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  &:hover {
    & ~ .nb-interactive-radio-group-input-tick {
      border: nb-border-weight(thin) nb-color(secondary, green-500) solid;
    }
  }

  &:focus-visible {
    & ~ .nb-interactive-radio-group-input-tick {
      outline-offset: nb-rem(5px);
      outline: nb-rem(1px) solid nb-color(primary, orange-900);
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;

    & ~ .nb-interactive-radio-group-input-tick {
      border: nb-border-weight(thin) nb-color(base, dark-100) solid;
      opacity: 0.4;
    }
  }
}

.nb-interactive-radio-group-input-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
}
