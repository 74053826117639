@import "styles/design-system";

.nb-lesson-details-chapters-sidebar-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: nb-rem(5px);
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
