@import "styles/design-system";

.nb-page-component-login-form {
  display: flex;
  flex-flow: column nowrap;
}

.nb-page-component-login-email {
  margin-bottom: nb-rem(20px);
}

.nb-page-component-login-form-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(20px);
}

.nb-page-component-login-request-password-button {
  @include nb-text-style-body2;
  @include nb-color-primary-grey-300;
  @include nb-shape;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  cursor: pointer;
  user-select: none;
  appearance: none;
  margin: 0;
  text-decoration: none;
  padding: 0;
  align-self: flex-start;
  border: nb-border-weight(thin) transparent solid;
  background: transparent;
  box-sizing: border-box;
  outline-color: nb-color(primary, orange-900);

  &:hover {
    @include nb-color-primary-orange-700;
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    @include nb-color-primary-orange-300;

    transition: none;
  }

  &:disabled {
    cursor: default;

    @include nb-color-primary-orange-300;
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}
