@use "sass:color";
@import "styles/design-system";

.nb-navigation-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: nb-rem(8px) nb-rem(40px);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  border-left: solid nb-rem(2px) transparent;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &--active,
  &:hover,
  &:focus-visible {
    border-left: solid nb-rem(2px) nb-color(primary, orange-900);
    background-color: color.adjust(nb-color(primary, orange-100), $alpha: 0.05);

    .nb-navigation-item-icon {
      path {
        stroke: nb-color(primary, orange-900);
      }
    }

    .nb-navigation-item-text {
      @include nb-color-primary-orange-900;
    }
  }

  &--active {
    .nb-navigation-item-text {
      @include nb-text-style-body2(bold, ptmono);
    }
  }

  &:active {
    transition: none;
    opacity: 0.5;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.nb-navigation-item-icon {
  font-size: 0;
  line-height: 0;
  width: nb-rem(15px);
  height: nb-rem(15px);
  margin-right: nb-rem(10px);

  path {
    transition: stroke nb-easing(in) nb-duration(base) nb-delay(base);
    stroke: nb-color(base, dark-900);
  }
}

.nb-navigation-item-text {
  @include nb-text-style-body2(regular, ptmono);
  @include nb-color-base-dark-900;

  transition: color nb-easing(in) nb-duration(base) nb-delay(base);
}
