@import "styles/design-system";

.nb-exercise-constructor-search-container {
  padding: nb-rem(25px) nb-rem(30px) 0;
}

.nb-exercise-constructor-search-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: nb-rem(10px);
}

.nb-exercise-constructor-search {
  margin-bottom: 0;
  width: 100%;
}
