@import "styles/design-system";

.nb-interactive-radio-group-rect-input-container {
  position: relative;
}

.nb-interactive-radio-group-rect-input-shape {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: nb-rem(112px);
  height: nb-rem(80px);
  background-color: nb-color(secondary, grey-100);
  border: nb-border-weight(thin) transparent solid;
  transition: all nb-easing(base) nb-duration(base) nb-delay(base);
  outline-color: nb-color(primary, orange-900);

  &--checked {
    border-color: nb-color(primary, orange-900);
  }
}

.nb-interactive-radio-group-rect-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 23;
  width: nb-rem(112px);
  height: nb-rem(80px);
  border: none;
  box-shadow: none;
  background: none;
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  &:hover {
    & ~ .nb-interactive-radio-group-rect-input-shape {
      border: nb-border-weight(thin) nb-color(primary, orange-900) solid;
    }
  }

  &:focus-visible {
    & ~ .nb-interactive-radio-group-rect-input-shape {
      outline-offset: nb-rem(5px);
      outline: nb-rem(1px) solid nb-color(primary, orange-900);
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;

    & ~ .nb-interactive-radio-group-rect-input-shape {
      border: nb-border-weight(thin) nb-color(base, dark-100) solid;
      opacity: 0.4;
    }
  }
}

.nb-interactive-radio-group-rect-input-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
}
