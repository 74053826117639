@import "styles/design-system";

.nb-sub-study-group-link {
  @include nb-shape;

  background-color: nb-color(base, white);
  padding: nb-rem(30px);
  width: 100%;
  margin-bottom: nb-rem(20px);
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;


  &:last-of-type {
    margin-bottom: 0;
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  @include nb-media-laptop {
    width: calc(50% - 10px);
    margin-right: nb-rem(20px);
  }

  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &:hover {
    background: nb-color(secondary, purple-300);
  }
}
