@import "styles/design-system";

.nb-interactive-true-or-false-assertion-wrapper {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: border nb-easing(in) nb-duration(base) nb-delay(base);
  border: nb-border-weight(thin) nb-color(secondary, grey-300) solid;
  width: 100%;

  &--error {
    border-color: nb-color(secondary, error-900);
  }

  &--focused {
    border-color: nb-color(secondary, blue-300);
  }
}

.nb-interactive-true-or-false-assertion-input {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
  @include nb-shape;

  display: flex;
  background: none;
  outline: none;
  filter: none;
  border: none;
  margin: 0;
  padding: nb-rem(7.5px) nb-rem(10px);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  width: 100%;
  min-width: nb-rem(150px);

  &::placeholder {
    @include nb-text-style-body2;
    @include nb-color-secondary-grey-900;
  }

  &:disabled {
    @include nb-color-secondary-grey-900;

    cursor: default;
    opacity: 0.4;
  }

  /* Hack to remove autofill browser styles */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:disabled,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 5000s;
  }

  /* Hide Arrows From Input Number */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
  }
}

.nb-interactive-true-or-false-assertion-select {
  @include nb-shape;

  width: nb-rem(95px);
  min-width: nb-rem(95px);
}

.nb-interactive-true-or-false-assertion-divider {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: nb-color(secondary, grey-300);
    width: nb-rem(1px);
    height: nb-rem(20px);
  }
}

.nb-interactive-true-or-false-assertion-error-text {
  @include nb-text-style-body2;
  @include nb-color-secondary-error-900;
}
