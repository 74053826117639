@import "styles/design-system";

.nb-common-content-modal {
  /* Default values for modal offsets
   These can be overridden by passing in a CSS variable */
  --nb-modal-default-outer-offset: 20px;
  --nb-modal-default-header-offset: 80px;
  --nb-modal-default-footer-offset: 80px;

  position: fixed;
  z-index: -100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition:
    visibility nb-easing(linear) nb-duration(none) nb-delay(hurry),
    opacity nb-duration(base) nb-delay(base),
    transform nb-duration(base) nb-delay(base);

  &--open {
    z-index: 90;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition:
      visibility nb-easing(linear) nb-duration(none) nb-delay(base),
      opacity nb-duration(base) nb-delay(base),
      transform nb-duration(base) nb-delay(base);
  }
}

.nb-common-content-modal-content {
  @include nb-shape;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: nb-color(base, white);
  width: nb-rem(370px);
  max-height: calc(100vh - var(--nb-modal-default-outer-offset));
}

.nb-common-content-modal-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: nb-rem(20px);
}

.nb-common-content-modal-title-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-right: nb-rem(10px);

  .nb-common-content-modal-emoji {
    @include nb-text-style-heading1;
    @include nb-color-base-dark-900;

    margin-right: nb-rem(5px);
  }

  .nb-common-content-modal-title {
    @include nb-text-style-heading1;
    @include nb-color-base-dark-900;

    margin: 0;
    padding: 0;
  }
}

.nb-common-content-modal-main {
  @include nb-scrollbar;

  /* stylelint-disable scss/operator-no-newline-before */
  --nb-modal-height-offset:
    calc(
      var(--nb-modal-default-outer-offset)
      + var(--nb-modal-default-header-offset)
      + var(--nb-modal-default-footer-offset)
    );
  /* stylelint-enable scss/operator-no-newline-before */

  width: 100%;
  max-height: calc(100vh - var(--nb-modal-height-offset));
  padding: 0 calc(nb-rem(20px) - $nb-scrollbar-width) nb-rem(20px) nb-rem(20px);
  overflow-x: hidden;
  overflow-y: auto;
}

.nb-common-content-modal-footer {
  padding: nb-rem(20px);
  padding-top: 0;
}
