@import "styles/design-system";

.nb-table-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: nb-rem(20px);
}

.nb-table-pagination-first,
.nb-table-pagination-previous,
.nb-table-pagination-last,
.nb-table-pagination-next {
  margin-right: nb-rem(10px);

  svg {
    transform: rotate(90deg);
  }
}

.nb-table-pagination-last,
.nb-table-pagination-next {
  svg {
    transform: rotate(270deg);
  }
}
