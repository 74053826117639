@import "styles/design-system";

.nb-sub-student-classroom-course-hero {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  padding: nb-rem(20px);
  background-color: nb-color(base, white);
  margin-bottom: nb-rem(20px);
}

.nb-sub-student-classroom-course-heading {
  margin-bottom: nb-rem(10px);

  &--emoji {
    margin-right: nb-rem(5px);
  }
}

.nb-sub-student-classroom-course-paragraph {
  opacity: 0.5;
  max-width: nb-rem(300px);
}

.nb-sub-student-classroom-course-image {
  width: nb-rem(171px);
  height: nb-rem(134px);
  margin-right: nb-rem(15px);
}

.nb-sub-student-classroom-course-poster {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  border: nb-border-weight(thin) nb-color(primary, grey-100) solid;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nb-sub-student-classroom-course-emoji {
  font-size: nb-rem(20px);
  line-height: nb-rem(28px);
}

.nb-sub-student-classroom-course-picture {
  object-fit: contain;
}

.nb-sub-student-classroom-course-actions {
  margin-left: auto;
}
