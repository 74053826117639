@import "styles/design-system";

.nb-sub-add-student-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.nb-sub-add-student-selects-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  .nb-interactive-select {
    width: nb-rem(158px);
    max-width: nb-rem(158px);
    min-width: nb-rem(158px);
  }
}
