@import "styles/design-system";

.nb-layout-app-body-scrollbar {
  @include nb-scrollbar;

  &--blocked {
    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    position: relative; /* [1] */
    overflow: hidden; /* [2] */
  }
}

.nb-layout-app-container {
  display: flex;
  flex: auto;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-height: 100vh;
}
