@import "styles/design-system";

.nb-interactive-answer-list-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.nb-interactive-answer-list-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-bottom: nb-rem(10px);
}

.nb-interactive-answer-list-add {
  margin-top: nb-rem(20px);
}
