@import "styles/design-system";

.nb-page-study-teacher-action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  min-width: nb-rem(95px);
}

.nb-page-study-teacher-edit {
  width: nb-rem(96px);
  min-width: nb-rem(96px);
  max-width: nb-rem(96px);
  margin-right: nb-rem(15px);
}
