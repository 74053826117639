@import "styles/design-system";

.nb-layout-auth-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: nb-color(secondary, grey-100);
}
