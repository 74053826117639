@import "styles/design-system";

.nb-interactive-clue-input-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  &--full {
    width: 100%;
  }
}

.nb-interactive-clue-input-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-bottom: nb-rem(10px);
}

.nb-interactive-clue-input {
  @include nb-shape(xlarge);
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  border-width: nb-rem(1px);
  border-style: solid;
  border-color: nb-color(secondary, grey-300);
  box-shadow: none;
  min-height: nb-rem(35px);
  min-width: nb-rem(124px);
  max-width: nb-rem(154px);
  width: auto;
  padding: nb-rem(6px) nb-rem(22px);
  display: flex;
  appearance: none;
  background: none;
  outline: none;
  filter: none;
  margin: 0;
  transition:
    border-color nb-easing(in) nb-duration(base) nb-delay(base),
    background-color nb-easing(in) nb-duration(base) nb-delay(base),
    opacity nb-easing(in) nb-duration(base) nb-delay(base),
    box-shadow nb-easing(in) nb-duration(base) nb-delay(base);
  text-align: center;

  &:hover:not(:disabled) {
    border-color: nb-color(secondary, blue-300);
    box-shadow: none;
  }

  &:active:not(:disabled) {
    border-color: nb-color(secondary, grey-300);
    box-shadow: none;
  }

  &::placeholder {
    @include nb-text-style-body2;
    @include nb-color-secondary-grey-900;
  }

  &:focus,
  &:focus-visible {
    border-color: nb-color(secondary, blue-300);
  }

  &:disabled {
    @include nb-color-secondary-grey-900;

    cursor: default;
    border-color: nb-color(secondary, grey-300);
    opacity: 0.7;
  }

  &--success {
    @include nb-color-base-dark-900;

    background-color: nb-color(secondary, green-100);
    border-color: nb-color(secondary, green-500);
    box-shadow: inset 0 0 0 nb-rem(100px) nb-color(secondary, green-100);

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      @include nb-color-base-dark-900;

      opacity: 1;
      background-color: nb-color(secondary, green-100);
      border-color: nb-color(secondary, green-500);
    }
  }

  &--error {
    @include nb-color-base-dark-900;

    background-color: nb-color(secondary, error-100);
    border-color: nb-color(secondary, error-900);
    box-shadow: inset 0 0 0 nb-rem(100px) nb-color(secondary, error-100);

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      @include nb-color-base-dark-900;

      opacity: 1;
      background-color: nb-color(secondary, error-100);
      border-color: nb-color(secondary, error-900);
    }
  }

  &--full {
    width: 100%;
    max-width: unset;
  }

  /* Hack to remove autofill browser styles */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:disabled,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 5000s;
  }

  /* Hide Arrows From Input Number */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
  }
}

.nb-interactive-clue-input-tooltip-container {
  align-self: auto;
  line-height: 0;
  cursor: pointer;
}

.nb-interactive-clue-input-tooltip {
  padding: nb-rem(4px) nb-rem(8px);
}

.nb-interactive-clue-shadow {
  @include nb-text-style-body2;

  opacity: 0;
  position: absolute;
  top: -100%;
  left: -100%;
  width: auto;
  height: 0;
  z-index: -999;
  pointer-events: none;
  visibility: hidden;
  border-width: nb-rem(1px);
  border-style: solid;
  border-color: nb-color(secondary, grey-300);
  box-shadow: none;
  padding: nb-rem(6px) nb-rem(22px);
  display: flex;
  appearance: none;
  background: none;
  outline: none;
  filter: none;
  margin: 0;
  text-align: center;
}
