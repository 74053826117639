@import "styles/design-system";

.nb-sub-classroom-add-course-modal {
  width: nb-rem(784px);
}

.nb-sub-classroom-add-course-list {
  /* Adaptive background */
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, nb-rem(171px));
  justify-content: start;
  align-items: stretch;
  gap: nb-rem(20px);
  max-width: 100%;
  width: 100%;
  background: nb-color(base, white);
  padding: 0;
  margin: 0;
  list-style: none;
}
