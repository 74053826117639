@import "styles/design-system";

.nb-exercise-essay-image {
  max-height: nb-rem(900px);
}

.nb-exercise-essay-text textarea {
  background: nb-color(secondary, grey-100);
  min-height: nb-rem(142px);

  &:disabled {
    opacity: 0.7;
    background: none;
  }
}

.nb-exercise-container {
  overflow: hidden;
  height: auto;
  transition: height 0.3s ease;

  &--collapsed {
    height: 0;
  }
}
