@import "styles/design-system";

.nb-common-image-container {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-self: start;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;

  &--full {
    display: flex;
    width: 100%;
    align-self: stretch;
  }

  &--rounded {
    @include nb-shape;
  }

  &--centered {
    align-self: center;
    justify-content: center;
  }

  &--skeleton {
    background-color: nb-color(secondary, grey-200);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background-image:
        linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
      animation: nb-common-image-shimmer 2s infinite;
    }
  }
}

.nb-common-image-figure {
  margin: 0;
}

.nb-common-image {
  width: 100%;
  height: auto;
  object-fit: contain;

  &--loading {
    opacity: 0;
  }

  &--rounded {
    @include nb-shape;
  }
}

.nb-common-image-caption {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-top: nb-rem(10px);
  text-align: center;
}

@keyframes nb-common-image-shimmer {
  100% {
    transform: translateX(100%);
  }
}
