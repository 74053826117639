@mixin nb-scrollbar($bg-color: nb-color(base, white)) {
  scrollbar-gutter: stable;

  &::-webkit-scrollbar-track {
    @include nb-shape;

    background-color: $bg-color;
  }

  &::-webkit-scrollbar {
    width: $nb-scrollbar-width;
    background-color: $bg-color;
  }

  &::-webkit-scrollbar-thumb {
    @include nb-shape;

    background-color: nb-color(base, dark-300);
    background-clip: padding-box;
  }
}
