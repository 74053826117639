@import "styles/design-system";

.nb-exercise-make-words-from-letters-tooltip-container {
  align-self: auto;
  display: flex;
  gap: 10px;

}

.nb-exercise-make-words-from-letters-tooltip {
  padding: nb-rem(4px) nb-rem(8px);
}
