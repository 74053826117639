@import "styles/design-system";

.nb-interactive-true-or-false-assertion-select .nb-interactive-true-or-false-assertion-select-option {
  @include nb-text-style-body2;
  @include nb-color-primary-grey-900;

  background: white;

  &:hover {
    background: nb-color(secondary, purple-300);
  }

  &--focused {
    background: nb-color(secondary, purple-300);
  }

  &--selected {
    background: nb-color(secondary, purple-300);
  }
}
