@import "styles/design-system";

.nb-interactive-select .nb-interactive-select-indicator-container {
  &--element {
    align-self: flex-start;
    padding: 0;
    padding-top: nb-rem(15px);
    padding-right: nb-rem(15px);
  }
}

.nb-interactive-select .nb-interactive-select-icon-chevron {
  width: nb-rem(15px);
  height: nb-rem(15px);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &--focused {
    transform: rotate(180deg);
  }

  path {
    stroke: nb-color(primary, grey-900);
  }
}
