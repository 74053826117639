@import "styles/design-system";

.nb-interactive-checkbox-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  gap: nb-rem(10px);

  &--full {
    width: 100%;
  }
}

.nb-interactive-checkbox-input-wrapper {
  position: relative;
}

.nb-interactive-checkbox-tick {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: nb-rem(24px);
  height: nb-rem(24px);
  background-color: nb-color(base, white);
  box-shadow: 0 7px 20px rgb(0 0 0 / 7%);
  border: nb-border-weight(thin) nb-color(base, dark-100) solid;
  transition: all nb-easing(in) nb-duration(slow) nb-delay(base);
  outline-color: nb-color(primary, orange-900);

  &--checked {
    border-color: nb-color(base, white);
  }
}

.nb-interactive-checkbox-tick-icon {
  width: nb-rem(10px);
  height: nb-rem(7px);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition:
    visibility nb-easing(linear) nb-duration(none) nb-delay(hurry),
    opacity nb-duration(base) nb-delay(base),
    transform nb-duration(base) nb-delay(base);

  path {
    stroke: nb-color(base, dark-900);
  }

  &--checked {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition:
      visibility nb-easing(linear) nb-duration(none) nb-delay(base),
      opacity nb-duration(base) nb-delay(base),
      transform nb-duration(base) nb-delay(base);
  }
}

.nb-interactive-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 23;
  width: nb-rem(24px);
  height: nb-rem(24px);
  border: none;
  box-shadow: none;
  background: none;
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  &:hover {
    & ~ .nb-interactive-checkbox-tick {
      border: nb-border-weight(thin) nb-color(base, dark-100) solid;
    }
  }

  &:focus-visible {
    & ~ .nb-interactive-checkbox-tick {
      outline-offset: nb-rem(5px);
      outline: nb-rem(1px) solid nb-color(primary, orange-900);
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;

    & ~ .nb-interactive-checkbox-tick {
      border: nb-border-weight(thin) nb-color(base, dark-100) solid;
      opacity: 0.4;
    }
  }
}

.nb-interactive-checkbox-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
}

.nb-interactive-checkbox-error-text {
  @include nb-text-style-body2;
  @include nb-color-secondary-error-900;

  margin-top: nb-rem(10px);
}
