@import "styles/design-system";

.nb-interactive-clue-select {
  .nb-interactive-clue-select-control {
    @include nb-shape(xlarge);

    display: block;
    border-width: nb-rem(1px);
    border-style: solid;
    border-color: nb-color(secondary, grey-300);
    box-shadow: none;
    min-height: nb-rem(35px);
    cursor: pointer;

    &:hover {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
    }

    &--focused {
      border-color: nb-color(secondary, grey-300);
      box-shadow: none;
    }

    &--open {
      box-shadow: none;
    }

    &--disabled {
      background: nb-color(secondary, grey-100);
      border-color: nb-color(secondary, grey-100);
      box-shadow: none;
    }

    &--correct {
      background: nb-color(secondary, green-100);
      border: solid nb-rem(1px) nb-color(secondary, green-500);
      cursor: default;

      &:hover {
        background: nb-color(secondary, green-100);
        border: solid nb-rem(1px) nb-color(secondary, green-500);
      }
    }

    &--fail {
      background: nb-color(secondary, error-100);
      border: solid nb-border-weight(thin) nb-color(secondary, error-900);
      cursor: default;

      &:hover {
        background: nb-color(secondary, error-100);
        border: solid nb-border-weight(thin) nb-color(secondary, error-900);
      }
    }
  }
}
