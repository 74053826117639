@import "styles/design-system";

.nb-sub-students-person-info-skeleton {
  @include nb-shape;

  width: 100%;
  min-height: nb-rem(50px);
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: nb-color(secondary, grey-100);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image:
      linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
    animation: nb-sub-students-person-info-shimmer 2s infinite;
  }
}

.nb-sub-students-person-info-container {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: transparent;
  position: relative;
}

.nb-sub-students-person-info-avatar {
  margin-right: nb-rem(15px);
}

.nb-sub-students-person-info-content {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.nb-sub-students-person-info-title {
  margin-bottom: nb-rem(1px);
}

.nb-sub-students-person-info-text {
  color: nb-color(base, dark-500);
}

@keyframes nb-sub-students-person-info-shimmer {
  100% {
    transform: translateX(100%);
  }
}
