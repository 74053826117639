@import "styles/design-system";

$nb-common-loader-color-primary: #9f9f9f;
$nb-common-loader-color-secondary: #606060;
$nb-common-loader-animation-duration: 1000ms;

.nb-common-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &--full {
    height: 100vh;
  }
}

.nb-common-loader-spinner {
  width: 75px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nb-common-loader-spinner-item {
  width: calc(75px / 12);
  height: 80%;
  animation: nb-common-loader-keyframes $nb-common-loader-animation-duration ease-in-out infinite;

  &:nth-child(1) {
    background: $nb-common-loader-color-primary;
    animation-delay: calc($nb-common-loader-animation-duration / 10 * -3);
  }

  &:nth-child(2) {
    background: $nb-common-loader-color-secondary;
    animation-delay: calc($nb-common-loader-animation-duration / 10 * -1);
  }

  &:nth-child(3) {
    background: $nb-common-loader-color-primary;
    animation-delay: calc($nb-common-loader-animation-duration / 10 * -2);
  }

  &:nth-child(4) {
    background: $nb-common-loader-color-secondary;
    animation-delay: calc($nb-common-loader-animation-duration / 10 * -1);
  }

  &:nth-child(5) {
    background: $nb-common-loader-color-primary;
    animation-delay: calc($nb-common-loader-animation-duration / 10 * -3);
  }

  @keyframes nb-common-loader-keyframes {
    50% {
      transform: scaleY(0.25);
    }
  }
}
