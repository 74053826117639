@import "styles/design-system";

.nb-interactive-clue-select .nb-interactive-clue-select-value-container {
  &:not(.nb-interactive-clue-select-value-container--has-value) {
    @include nb-visibility-hidden;
  }

  &--has-value {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: nb-rem(5px) nb-rem(20px);
  }
}
