@import "styles/design-system";

.nb-notification-container {
  &.Toastify__toast {
    @include nb-shape;

    padding: nb-rem(20px);
  }

  &--error.Toastify__toast {
    background-color: nb-color(secondary, error-100);
    border: solid nb-rem(1px) nb-color(secondary, error-700);
  }

  &--success.Toastify__toast {
    background-color: nb-color(secondary, green-100);
    border: solid nb-rem(1px) nb-color(secondary, green-700);
  }
}

.nb-notification-body {
  &.Toastify__toast-body {
    @include nb-text-style-body2;
    @include nb-color-base-dark-900;

    padding: 0;
    display: flex;
    align-items: baseline;
  }

  & .Toastify__toast-icon {
    @include nb-text-style-body2;
    @include nb-color-base-dark-900;

    width: auto;
    margin-right: nb-rem(5px);
  }
}
