@import "styles/design-system";

.nb-sub-edit-course-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.nb-sub-edit-course-avatar {
  margin: nb-rem(5px) auto nb-rem(10px);
}
