@import "styles/design-system";

.nb-page-user-settings-form {
  width: nb-rem(377px);

  .nb-page-user-settings-form-input-icon {
    path {
      stroke: none;
    }
  }

  .nb-page-user-settings-form-button {
    @include nb-text-style-body2;
    @include nb-color-primary-orange-900;
    @include nb-shape;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);
    cursor: pointer;
    user-select: none;
    appearance: none;
    margin: 0;
    padding: 0;
    align-self: flex-start;
    border: nb-border-weight(thin) transparent solid;
    background: transparent;
    box-sizing: border-box;
    outline-color: nb-color(primary, orange-900);

    &:hover {
      @include nb-color-primary-orange-700;
    }

    &:focus-visible {
      outline-offset: nb-rem(5px);
      outline: nb-rem(1px) solid nb-color(primary, orange-900);
    }

    &:active {
      @include nb-color-primary-orange-300;

      transition: none;
    }

    &:disabled {
      cursor: default;

      @include nb-color-primary-orange-300;
    }

    &:active:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
