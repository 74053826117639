@import "styles/design-system";

.nb-lesson-details-chapters-sidebar {
  @include nb-shape;

  grid-area: chapters-sidebar;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: nb-rem(20px);
  background-color: nb-color(base, white);
  padding: nb-rem(30px);
}

.nb-lesson-details-chapters-loader {
  padding: nb-rem(20px) 0;
}
