@import "styles/design-system";

.nb-interactive-clue-select .nb-interactive-clue-select-option {
  @include nb-text-style-body2;
  @include nb-color-primary-grey-900;

  text-align: center;
  background: transparent;
  cursor: pointer;
  padding: 0 nb-rem(10px);

  &:hover {
    @include nb-color-primary-orange-900;

    background: transparent;
  }

  &--focused {
    @include nb-color-primary-orange-900;

    background: transparent;
  }

  &--selected {
    @include nb-color-primary-orange-900;

    background: transparent;
  }
}
