@import "styles/design-system";

.nb-interactive-radio-group-wrapper {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  &--full {
    display: flex;
    width: 100%;
  }
}

.nb-interactive-radio-group-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-bottom: nb-rem(10px);
}

.nb-interactive-radio-group-error-text {
  @include nb-text-style-body2;
  @include nb-color-secondary-error-900;

  margin-top: nb-rem(10px);
}
