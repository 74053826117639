@import "styles/design-system";

.nb-interactive-search .nb-interactive-search-option {
  @include nb-text-style-body2;
  @include nb-color-secondary-grey-900;

  background: white;
  padding-left: nb-rem(35px);

  &:hover {
    background: nb-color(secondary, purple-300);
  }

  &--focused {
    background: nb-color(secondary, purple-300);
  }

  &--searched {
    background: nb-color(secondary, purple-300);
  }

  &--highlighted {
    @include nb-color-base-dark-900;

    background-color: transparent;
  }
}
