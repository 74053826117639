@import "styles/design-system";

.nb-interactive-search-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: nb-rem(20px);
}

.nb-interactive-search-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  margin-bottom: nb-rem(10px);
}

.nb-interactive-search {
  min-width: nb-rem(196px);

  &--full {
    width: 100%;
  }
}
