@import "styles/design-system";

.nb-typography-text-body-1 {
  @include nb-text-style-body1;
  @include nb-color-base-dark-900;

  display: inline-block;
  padding: 0;
  margin: 0;

  &--bold {
    @include nb-text-style-body1(bold);
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-text-body-2 {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  display: inline-block;
  padding: 0;
  margin: 0;

  &--bold {
    @include nb-text-style-body2(bold);
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-text-caption {
  @include nb-text-style-caption;
  @include nb-color-base-dark-900;

  display: inline-block;
  padding: 0;
  margin: 0;

  &--line {
    @include nb-text-style-caption-underline;
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-text-label {
  @include nb-text-style-label;
  @include nb-color-base-dark-900;

  display: inline-block;
  padding: 0;
  margin: 0;

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-text-line-break {
  white-space: pre-wrap;
}
