@import "styles/design-system";

.nb-interactive-true-or-false-assertion-select {
  .nb-interactive-true-or-false-assertion-select-control {
    border: solid 1px transparent;
    box-shadow: none;
    min-height: nb-rem(38px);
    cursor: pointer;

    &:hover {
      border: solid 1px transparent;
      box-shadow: none;
    }

    &--focused {
      border: solid 1px transparent;
      box-shadow: none;
    }

    &--disabled {
      border: solid 1px transparent;
      background: transparent;
      box-shadow: none;
      opacity: 0.4;
    }

    &--open {
      box-shadow: none;
    }
  }
}
