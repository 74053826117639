@import "styles/design-system";

.nb-table-body-cell {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  &:last-child {
    padding-right: 0;
  }
}

.nb-table-body-cell-inner-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.nb-table-body-cell-inner-icon {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  margin-right: nb-rem(5px);
  padding: nb-rem(5px);
  cursor: grab;
}

.nb-table-body-cell-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}
