@import "styles/design-system";

.nb-exercise-container {
  overflow: hidden;
  height: auto;
  transition: height 0.3s ease;

  &--collapsed {
    height: 0;
  }
}
