@import "styles/design-system";

.nb-interactive-select-portal .nb-interactive-select-menu-list {
  @include nb-scrollbar;

  scrollbar-gutter: auto;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0 nb-rem(6px) nb-rem(6px);
}
