@import "styles/design-system";

.nb-interactive-clue-select {
  .nb-interactive-clue-select-dropdown-indicator {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    &--has-value {
      @include nb-visibility-hidden;
    }
  }

  .nb-interactive-clue-select-icon-chevron {
    width: nb-rem(15px);
    height: nb-rem(15px);
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);

    path {
      stroke: nb-color(primary, grey-900);
    }

    &--focused {
      transform: rotate(180deg);

      path {
        stroke: nb-color(base, dark-300);
      }
    }
  }
}
