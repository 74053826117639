@import "styles/design-system";

.nb-admin-add-offer-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  gap: nb-rem(10px);

  .nb-interactive-input-wrapper {
    margin-bottom: 0;
  }
}

.nb-admin-add-offer-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
}

.nb-admin-add-offer-input-field {
  width: 100%;

  &--half {
    width: 50%;

    .nb-interactive-input-holder {
      min-width: unset;
    }
  }
}
