@import "styles/design-system";

.nb-sub-materials-details-hero {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  padding: nb-rem(20px);
  background-color: nb-color(base, white);
  margin-bottom: nb-rem(20px);
}

.nb-sub-materials-details-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
