@import "styles/design-system";

.nb-sub-add-teacher-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.nb-sub-add-teacher-avatar-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: nb-rem(10px);
}
