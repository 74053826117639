@import "styles/design-system";

.nb-interactive-video-player-wrapper {
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  /* Adding skeleton fallback as background for player with simmer animation.
  This skeleton will be shown when video url is invalid or network is slow to load previews */
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--rounded {
    @include nb-shape;
  }

  &--loading {
    background-color: nb-color(secondary, grey-200);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background-image:
        linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
      animation: nb-video-loader-shimmer 2s infinite;
    }
  }
}

.nb-interactive-video-player {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes nb-video-loader-shimmer {
  100% {
    transform: translateX(100%);
  }
}
