@import "styles/design-system";

.nb-sub-materials-search-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(20px);
}

.nb-sub-materials-search {
  width: 100%;
  margin-bottom: 0;
}

.nb-sub-materials-tabs {
  min-width: nb-rem(300px);
  max-width: nb-rem(300px);
  width: nb-rem(300px);
  margin-left: nb-rem(20px);
  margin-bottom: 0;
}
