@import "styles/design-system";

.nb-common-action-modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition:
    visibility nb-easing(linear) nb-duration(none) nb-delay(hurry),
    opacity nb-duration(base) nb-delay(base),
    transform nb-duration(base) nb-delay(base);

  &--open {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition:
      visibility nb-easing(linear) nb-duration(none) nb-delay(base),
      opacity nb-duration(base) nb-delay(base),
      transform nb-duration(base) nb-delay(base);
  }
}

.nb-common-action-modal-content {
  @include nb-shape;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: nb-color(base, white);
  padding: nb-rem(20px);
  width: nb-rem(468px);
}

.nb-common-action-modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.nb-common-action-modal-main {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 nb-rem(20px);

  .nb-common-action-modal-emoji {
    @include nb-text-style-heading1;
    @include nb-color-base-dark-900;
  }

  .nb-common-action-modal-text {
    @include nb-text-style-heading1;
    @include nb-color-base-dark-900;

    margin: 0;
    padding: 0 nb-rem(50px);
    text-align: center;
  }
}

.nb-common-action-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .nb-common-action-modal-button {
    width: nb-rem(204px);
  }
}
