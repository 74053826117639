@import "styles/design-system";

.nb-page-materials-details-action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.nb-page-materials-details-action-edit,
.nb-page-materials-details-action-delete {
  margin-right: nb-rem(10px);
}
