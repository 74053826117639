@import "styles/design-system";

.nb-drag-and-drop-draggable-start-zone {
  @include nb-shape;

  min-height: nb-rem(83px);
  padding: nb-rem(20px);
  border: nb-border-weight(thin) nb-color(secondary, grey-300) dashed;
  transition:
    border nb-duration(slow) nb-easing(base) nb-delay(base),
    box-shadow nb-duration(slow) nb-easing(base) nb-delay(base);

  &--over {
    border: nb-border-weight(thin) nb-color(secondary, grey-300) solid;
    box-shadow: inset 0 0 0 nb-rem(2px) nb-color(secondary, grey-300);
  }
}
