@import "styles/design-system";

.nb-lesson-details-homework-exercises-wrapper {
  &:last-of-type {
    .nb-lesson-details-homework-exercises-divider {
      display: none;
    }
  }
}

.nb-lesson-details-homework-exercises {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: nb-rem(20px);
}
