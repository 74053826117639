@import "styles/design-system";

/* Resetter */
.fc td,
.fc th,
.fc table.fc-scrollgrid {
  border: none;
}

.fc .fc-event {
  border: none;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: nb-rem(5px);
}

.fc .fc-event-main {
  display: flex;
  padding: 0;
  height: auto;
}

/* Overwriting fullcalendar styles */
.nb-sub-schedule-view {
  .nb-sub-schedule-day-cell {
    border-bottom: nb-color(secondary, grey-100) nb-border-weight(thin) solid;
    border-right: nb-color(secondary, grey-100) nb-border-weight(thin) solid;
    background: nb-color(base, white) !important;

    &--student .fc-highlight {
      background-color: transparent;
    }
  }

  .nb-sub-schedule-slot-lane {
    border-bottom: nb-color(secondary, grey-100) nb-border-weight(thin) solid;
    border-right: nb-color(secondary, grey-100) nb-border-weight(thin) solid;
    height: nb-rem(50px);
  }

  .nb-sub-schedule-day-cell.fc-day-mon {
    border-top-left-radius: nb-shape(small);
    border-bottom-left-radius: nb-shape(small);
  }

  .nb-sub-schedule-day-cell.fc-day-sun {
    border-top-right-radius: nb-shape(small);
    border-bottom-right-radius: nb-shape(small);
  }

  .nb-sub-schedule-event {
    @include nb-shape;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: nb-color(secondary, blue-100);
    width: 100%;
    height: 100%;
    padding: 0 nb-rem(10px);
    cursor: pointer;
  }

  .nb-sub-schedule-event-color {
    &--light-blue {
      background-color: nb-color(secondary, blue-100);
    }

    &--light-orange {
      background-color: nb-color(primary, orange-100);
    }

    &--light-purple {
      background-color: nb-color(secondary, purple-100);
    }

    &--light-green {
      background-color: nb-color(secondary, green-100);
    }

    &--light-brown {
      background-color: nb-color(secondary, brown-100);
    }

    &--blue {
      background-color: nb-color(secondary, blue-300);
    }

    &--orange {
      background-color: nb-color(primary, orange-300);
    }

    &--purple {
      background-color: nb-color(secondary, purple-300);
    }

    &--green {
      background-color: nb-color(secondary, green-300);
    }

    &--brown {
      background-color: nb-color(secondary, brown-300);
    }
  }
}

.nb-schedule-event-text {
  color: nb-color(base, dark-900);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.nb-schedule-day-header-label {
  @include nb-color-base-dark-900;

  text-transform: uppercase;
  padding-bottom: nb-rem(8px);

  &--weekday {
    opacity: 0.2;
  }

  &--date {
  }
}

.nb-schedule-slot-label-text {
  @include nb-color-base-dark-900;

  padding: 0 nb-rem(9px);
}
