@import "styles/design-system";

.nb-exercise-constructor-secondary {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  background: nb-color(base, white);
  width: nb-rem(367px);
  height: 100vh;
  border-left: nb-rem(1px) solid nb-color(secondary, grey-200);
  transform: translateX(-100%);
  transition: transform nb-duration(base) nb-delay(base);

  &--open {
    transform: translateX(0);
    transition: transform nb-duration(base) nb-delay(base);
  }
}
