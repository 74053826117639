@import "styles/design-system";

.nb-lesson-details-homework-sidebar {
  grid-area: homework-sidebar;
}

.nb-lesson-details-homework-sidebar-button {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: nb-rem(5px);
  padding: nb-rem(20px) nb-rem(30px);
  margin: 0;
  background: nb-color(base, white);
  appearance: none;
  border: none;
  user-select: none;
  cursor: pointer;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  outline-color: nb-color(primary, orange-900);

  &:hover {
    background: nb-color(secondary, purple-300);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    background: nb-color(secondary, purple-100);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }

  &--active {
    background: nb-color(secondary, purple-300);

    &:active {
      background: nb-color(secondary, purple-300);
    }
  }
}
