@import "styles/design-system";

.nb-lesson-details-content-chapter-wrapper {
  &:last-of-type {
    .nb-lesson-details-content-chapter-divider {
      display: none;
    }
  }
}

.nb-lesson-details-content-chapter {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: nb-rem(20px);
}

.nb-lesson-details-content-chapter-title {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: nb-rem(10px);

  &--highlight {
    animation: nb-highlight-chapter-title 1s linear infinite;
  }
}

@keyframes nb-highlight-chapter-title {
  0% { background: nb-color(base, white); }
  50% { background: nb-color(secondary, purple-300); }
  100% { background: nb-color(base, white); }
}
