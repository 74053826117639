@use "sass:color";
@import "styles/design-system";

.nb-interactive-profile-dropdown-content {
  max-width: nb-rem(350px);
}

.nb-interactive-profile-dropdown-header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: nb-rem(30px);
  padding-bottom: 0;
}

.nb-interactive-profile-dropdown-user-name {
  margin-bottom: nb-rem(5px);
}

.nb-interactive-profile-dropdown-user-role {
  text-transform: lowercase;
}

.nb-interactive-profile-dropdown-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: nb-rem(5px);
  list-style: none;
  margin: 0;
  padding: nb-rem(12px) 0 nb-rem(20px);
}

.nb-interactive-profile-dropdown-item > a,
.nb-interactive-profile-dropdown-item > button, {
  @include nb-text-style-body2;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: nb-rem(8px) nb-rem(30px);
  cursor: pointer;
  outline: none;
  user-select: none;
  text-decoration: none;
  border: none;
  border-left: solid nb-rem(2px) transparent;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  background-color: nb-color(base, white);
  color: nb-color(base, dark-900);

  &:hover,
  &:focus-visible {
    border-left: solid nb-rem(2px) nb-color(primary, orange-900);
    background-color: color.adjust(nb-color(primary, orange-100), $alpha: 0.05);
    color: nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    opacity: 0.5;
  }
}
