@import "styles/design-system";

.nb-interactive-cover-button {
  @include nb-shape;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  border: none;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  cursor: pointer;
  user-select: none;
  appearance: none;
  margin: 0;
  padding: nb-rem(20px) nb-rem(15px);
  background: nb-color(secondary, brown-100);
  box-sizing: border-box;
  outline-color: nb-color(primary, orange-900);
  width: nb-rem(171px);
  height: nb-rem(219px);

  &:hover {
    background: nb-color(secondary, brown-300);

    .nb-interactive-cover-button-content {
      border-color: nb-color(secondary, brown-500);
    }

    .nb-interactive-cover-button-content::before,
    .nb-interactive-cover-button-content::after {
      background: nb-color(secondary, brown-500);
    }
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    background: nb-color(secondary, brown-100);
    padding: nb-rem(23px) nb-rem(18px);
  }

  &:disabled {
    cursor: default;
    background: nb-color(secondary, grey-300);
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

.nb-interactive-cover-button-content {
  @include nb-shape;

  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: nb-border-weight(thin) nb-color(secondary, brown-300) dashed;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  font-size: 0;
  line-height: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: nb-rem(10px);
    height: nb-rem(2px);
    background: nb-color(secondary, brown-300);
    transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
