@import "styles/design-system";

.nb-lesson-details-container {
  display: grid;
  grid-template: "lesson-content lesson-sidebar" auto / 1fr nb-rem(351px);
  gap: 0 nb-rem(20px);
  grid-auto-flow: row;
  justify-content: start;
  align-content: start;
  justify-items: stretch;
  align-items: start;
}
