@import "styles/design-system";

.nb-common-empty-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.nb-common-empty-image {
  display: block;
  margin-bottom: nb-rem(32px);

  &--1 {
    width: nb-rem(256px);
    height: nb-rem(167px);
  }

  &--2 {
    width: nb-rem(129px);
    height: nb-rem(203px);
  }

  &--3 {
    width: nb-rem(201px);
    height: nb-rem(154px);
  }

  &--4 {
    width: nb-rem(268px);
    height: nb-rem(148px);
  }

  &--5 {
    width: nb-rem(188px);
    height: nb-rem(188px);
  }
}
