@import "styles/design-system";

.nb-table-head-cell {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  opacity: 0.4;
}
