@import "styles/design-system";

.nb-exercise-make-words-from-letters-droppable {
  @include nb-text-style-body2;
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  min-height: nb-rem(60px);
  min-width: nb-rem(60px);
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  border: none;
  cursor: default;
  box-sizing: border-box;
  color: transparent;
  background: nb-color(base, white);
  box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, grey-300);
  transition: box-shadow nb-duration(slow) nb-easing(base) nb-delay(base);

  &--dragging {
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, purple-300);
  }

  &--over {
    box-shadow: inset 0 0 0 nb-rem(3px) nb-color(secondary, purple-300);
  }

  &--dropped {
    box-shadow: none;
  }

  &--over.nb-exercise-make-words-from-letters-droppable--dropped {
    box-shadow: 0 0 0 nb-rem(3px) nb-color(secondary, purple-300);
  }
}
