@import "styles/design-system";

$nb-interactive-dropdown-space-size-none: 0;
$nb-interactive-dropdown-space-size-xsm: 5px;
$nb-interactive-dropdown-space-size-sm: 10px;
$nb-interactive-dropdown-space-size-md: 15px;
$nb-interactive-dropdown-space-size-l: 20px;
$nb-interactive-dropdown-space-size-xl: 25px;
$nb-interactive-dropdown-space-size-xxl: 30px;

.nb-interactive-dropdown-container {
  position: relative;
  display: inline-block;
  align-self: flex-start;
}

.nb-interactive-dropdown-content {
  @include nb-shape;

  position: absolute;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  width: max-content;
  padding: 0;
  background-color: nb-color(base, white);
  border: none;
  transition:
    opacity nb-easing(in) nb-duration(fast) nb-delay(base),
    visibility nb-easing(in) nb-duration(fast) nb-delay(base),
    transform nb-easing(linear) nb-duration(fast) nb-delay(base);
  margin: $nb-interactive-dropdown-space-size-none;

  // Using CSS variables to create animation with flexible configuration ability
  transform:
    var(--nb-interactive-dropdown-transform-translate,)
    var(--nb-interactive-dropdown-transform-scale,);
  transform-origin: var(--nb-interactive-dropdown-transform-origin,);

  @at-root #{&}--size-xsm#{&}--top, #{&}--size-xsm#{&}--top-start, #{&}--size-xsm#{&}--top-end {
    margin-bottom: nb-rem($nb-interactive-dropdown-space-size-xsm);
  }

  @at-root #{&}--size-sm#{&}--top, #{&}--size-sm#{&}--top-start, #{&}--size-sm#{&}--top-end {
    margin-bottom: nb-rem($nb-interactive-dropdown-space-size-sm);
  }

  @at-root #{&}--size-md#{&}--top, #{&}--size-md#{&}--top-start, #{&}--size-md#{&}--top-end {
    margin-bottom: nb-rem($nb-interactive-dropdown-space-size-md);
  }

  @at-root #{&}--size-l#{&}--top, #{&}--size-l#{&}--top-start, #{&}--size-l#{&}--top-end {
    margin-bottom: nb-rem($nb-interactive-dropdown-space-size-l);
  }

  @at-root #{&}--size-xl#{&}--top, #{&}--size-xl#{&}--top-start, #{&}--size-xl#{&}--top-end {
    margin-bottom: nb-rem($nb-interactive-dropdown-space-size-xl);
  }

  @at-root #{&}--size-xxl#{&}--top, #{&}--size-xxl#{&}--top-start, #{&}--size-xxl#{&}--top-end {
    margin-bottom: nb-rem($nb-interactive-dropdown-space-size-xxl);
  }

  @at-root #{&}--size-xsm#{&}--right, #{&}--size-xsm#{&}--right-start, #{&}--size-xsm#{&}--right-end {
    margin-left: nb-rem($nb-interactive-dropdown-space-size-xsm);
  }

  @at-root #{&}--size-sm#{&}--right, #{&}--size-sm#{&}--right-start, #{&}--size-sm#{&}--right-end {
    margin-left: nb-rem($nb-interactive-dropdown-space-size-sm);
  }

  @at-root #{&}--size-md#{&}--right, #{&}--size-md#{&}--right-start, #{&}--size-md#{&}--right-end {
    margin-left: nb-rem($nb-interactive-dropdown-space-size-md);
  }

  @at-root #{&}--size-l#{&}--right, #{&}--size-l#{&}--right-start, #{&}--size-l#{&}--right-end {
    margin-left: nb-rem($nb-interactive-dropdown-space-size-l);
  }

  @at-root #{&}--size-xl#{&}--right, #{&}--size-xl#{&}--right-start, #{&}--size-xl#{&}--right-end {
    margin-left: nb-rem($nb-interactive-dropdown-space-size-xl);
  }

  @at-root #{&}--size-xxl#{&}--right, #{&}--size-xxl#{&}--right-start, #{&}--size-xxl#{&}--right-end {
    margin-left: nb-rem($nb-interactive-dropdown-space-size-xxl);
  }

  @at-root #{&}--size-xsm#{&}--bottom, #{&}--size-xsm#{&}--bottom-end, #{&}--size-xsm#{&}--bottom-start {
    margin-top: nb-rem($nb-interactive-dropdown-space-size-xsm);
  }

  @at-root #{&}--size-sm#{&}--bottom, #{&}--size-sm#{&}--bottom-end, #{&}--size-sm#{&}--bottom-start {
    margin-top: nb-rem($nb-interactive-dropdown-space-size-sm);
  }

  @at-root #{&}--size-md#{&}--bottom, #{&}--size-md#{&}--bottom-end, #{&}--size-md#{&}--bottom-start {
    margin-top: nb-rem($nb-interactive-dropdown-space-size-md);
  }

  @at-root #{&}--size-l#{&}--bottom, #{&}--size-l#{&}--bottom-end, #{&}--size-l#{&}--bottom-start {
    margin-top: nb-rem($nb-interactive-dropdown-space-size-l);
  }

  @at-root #{&}--size-xl#{&}--bottom, #{&}--size-xl#{&}--bottom-end, #{&}--size-xl#{&}--bottom-start {
    margin-top: nb-rem($nb-interactive-dropdown-space-size-xl);
  }

  @at-root #{&}--size-xxl#{&}--bottom, #{&}--size-xxl#{&}--bottom-end, #{&}--size-xxl#{&}--bottom-start {
    margin-top: nb-rem($nb-interactive-dropdown-space-size-xxl);
  }

  @at-root #{&}--size-xsm#{&}--left, #{&}--size-xsm#{&}--left-start, #{&}--size-xsm#{&}--left-end {
    margin-right: nb-rem($nb-interactive-dropdown-space-size-xsm);
  }

  @at-root #{&}--size-sm#{&}--left, #{&}--size-sm#{&}--left-start, #{&}--size-sm#{&}--left-end {
    margin-right: nb-rem($nb-interactive-dropdown-space-size-sm);
  }

  @at-root #{&}--size-md#{&}--left, #{&}--size-md#{&}--left-start, #{&}--size-md#{&}--left-end {
    margin-right: nb-rem($nb-interactive-dropdown-space-size-md);
  }

  @at-root #{&}--size-l#{&}--left, #{&}--size-l#{&}--left-start, #{&}--size-l#{&}--left-end {
    margin-right: nb-rem($nb-interactive-dropdown-space-size-l);
  }

  @at-root #{&}--size-xl#{&}--left, #{&}--size-xl#{&}--left-start, #{&}--size-xl#{&}--left-end {
    margin-right: nb-rem($nb-interactive-dropdown-space-size-xl);
  }

  @at-root #{&}--size-xxl#{&}--left, #{&}--size-xxl#{&}--left-start, #{&}--size-xxl#{&}--left-end {
    margin-right: nb-rem($nb-interactive-dropdown-space-size-xxl);
  }

  &--top {
    --nb-interactive-dropdown-transform-scale: scale(0.75, 0.55);
    --nb-interactive-dropdown-transform-translate: translate(-50%, 0);
    --nb-interactive-dropdown-transform-origin: center bottom;

    bottom: 100%;
    left: 50%;
  }

  &--top-start {
    --nb-interactive-dropdown-transform-scale: scale(0.75, 0.55);
    --nb-interactive-dropdown-transform-origin: center bottom;

    bottom: 100%;
    left: 0;
  }

  &--top-end {
    --nb-interactive-dropdown-transform-scale: scale(0.75, 0.55);
    --nb-interactive-dropdown-transform-origin: center bottom;

    bottom: 100%;
    right: 0;
  }

  &--right {
    --nb-interactive-dropdown-transform-scale: scale(0.55, 0.75);
    --nb-interactive-dropdown-transform-translate: translate(0, -50%);
    --nb-interactive-dropdown-transform-origin: left center;

    top: 50%;
    left: 100%;
  }

  &--right-start {
    --nb-interactive-dropdown-transform-scale: scale(0.55, 0.75);
    --nb-interactive-dropdown-transform-origin: left center;

    top: 0;
    left: 100%;
  }

  &--right-end {
    --nb-interactive-dropdown-transform-scale: scale(0.55, 0.75);
    --nb-interactive-dropdown-transform-origin: left center;

    bottom: 0;
    left: 100%;
  }

  &--bottom {
    --nb-interactive-dropdown-transform-scale: scale(0.75, 0.55);
    --nb-interactive-dropdown-transform-translate: translate(-50%, 0);
    --nb-interactive-dropdown-transform-origin: center top;

    top: 100%;
    left: 50%;
  }

  &--bottom-start {
    --nb-interactive-dropdown-transform-scale: scale(0.75, 0.55);
    --nb-interactive-dropdown-transform-origin: center top;

    top: 100%;
    left: 0;
  }

  &--bottom-end {
    --nb-interactive-dropdown-transform-scale: scale(0.75, 0.55);
    --nb-interactive-dropdown-transform-origin: center top;

    top: 100%;
    right: 0;
  }

  &--left {
    --nb-interactive-dropdown-transform-scale: scale(0.55, 0.75);
    --nb-interactive-dropdown-transform-translate: translate(0, -50%);
    --nb-interactive-dropdown-transform-origin: right center;

    top: 50%;
    right: 100%;
  }

  &--left-start {
    --nb-interactive-dropdown-transform-scale: scale(0.55, 0.75);
    --nb-interactive-dropdown-transform-origin: right center;

    top: 0;
    right: 100%;
  }

  &--left-end {
    --nb-interactive-dropdown-transform-scale: scale(0.55, 0.75);
    --nb-interactive-dropdown-transform-origin: right center;

    bottom: 0;
    right: 100%;
  }

  &--visible {
    --nb-interactive-dropdown-transform-scale: scale(1, 1);

    opacity: 1;
    visibility: visible;
  }

  &--bordered {
    border: nb-border-weight(thin) nb-color(secondary, grey-200) solid;
  }

  &--disabled {
    display: none;
  }
}

.nb-interactive-dropdown-main {
  width: 100%;
}
