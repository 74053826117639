@import "styles/design-system";

.nb-page-admin-landing-container {
  @include nb-shape;

  background-color: nb-color(base, white);
  width: 100%;
  height: 100%;
  padding: nb-rem(50px);
}

.nb-page-admin-landing-search {
  width: 100%;
  margin-bottom: 0;
}

.nb-page-admin-landing-add-button {
  width: nb-rem(96px);
  max-width: nb-rem(96px);
  min-width: nb-rem(96px);
}
