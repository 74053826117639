// Primary colors
@mixin nb-color-primary-grey-900() {
  color: nb-color(primary, grey-900);
}

@mixin nb-color-primary-grey-700() {
  color: nb-color(primary, grey-700);
}

@mixin nb-color-primary-grey-500() {
  color: nb-color(primary, grey-500);
}

@mixin nb-color-primary-grey-300() {
  color: nb-color(primary, grey-300);
}

@mixin nb-color-primary-grey-100() {
  color: nb-color(primary, grey-100);
}

@mixin nb-color-primary-orange-900() {
  color: nb-color(primary, orange-900);
}

@mixin nb-color-primary-orange-700() {
  color: nb-color(primary, orange-700);
}

@mixin nb-color-primary-orange-500() {
  color: nb-color(primary, orange-500);
}

@mixin nb-color-primary-orange-300() {
  color: nb-color(primary, orange-300);
}

@mixin nb-color-primary-orange-100() {
  color: nb-color(primary, orange-100);
}

// Secondary colors
@mixin nb-color-secondary-error-900() {
  color: nb-color(secondary, error-900);
}

@mixin nb-color-secondary-error-700() {
  color: nb-color(secondary, error-700);
}

@mixin nb-color-secondary-error-500() {
  color: nb-color(secondary, error-500);
}

@mixin nb-color-secondary-error-300() {
  color: nb-color(secondary, error-300);
}

@mixin nb-color-secondary-error-100() {
  color: nb-color(secondary, error-100);
}

@mixin nb-color-secondary-grey-900() {
  color: nb-color(secondary, grey-900);
}

@mixin nb-color-secondary-grey-700() {
  color: nb-color(secondary, grey-700);
}

@mixin nb-color-secondary-grey-500() {
  color: nb-color(secondary, grey-500);
}

@mixin nb-color-secondary-grey-300() {
  color: nb-color(secondary, grey-300);
}

@mixin nb-color-secondary-grey-200() {
  color: nb-color(secondary, grey-200);
}

@mixin nb-color-secondary-grey-100() {
  color: nb-color(secondary, grey-100);
}

@mixin nb-color-secondary-green-900() {
  color: nb-color(secondary, green-900);
}

@mixin nb-color-secondary-green-700() {
  color: nb-color(secondary, green-700);
}

@mixin nb-color-secondary-green-500() {
  color: nb-color(secondary, green-500);
}

@mixin nb-color-secondary-green-300() {
  color: nb-color(secondary, green-300);
}

@mixin nb-color-secondary-green-100() {
  color: nb-color(secondary, green-100);
}

@mixin nb-color-secondary-blue-900() {
  color: nb-color(secondary, blue-900);
}

@mixin nb-color-secondary-blue-700() {
  color: nb-color(secondary, blue-700);
}

@mixin nb-color-secondary-blue-500() {
  color: nb-color(secondary, blue-500);
}

@mixin nb-color-secondary-blue-300() {
  color: nb-color(secondary, blue-300);
}

@mixin nb-color-secondary-blue-100() {
  color: nb-color(secondary, blue-100);
}

@mixin nb-color-secondary-purple-900() {
  color: nb-color(secondary, purple-900);
}

@mixin nb-color-secondary-purple-700() {
  color: nb-color(secondary, purple-700);
}

@mixin nb-color-secondary-purple-500() {
  color: nb-color(secondary, purple-500);
}

@mixin nb-color-secondary-purple-300() {
  color: nb-color(secondary, purple-300);
}

@mixin nb-color-secondary-purple-100() {
  color: nb-color(secondary, purple-100);
}

@mixin nb-color-secondary-brown-900() {
  color: nb-color(secondary, brown-900);
}

@mixin nb-color-secondary-brown-700() {
  color: nb-color(secondary, brown-700);
}

@mixin nb-color-secondary-brown-500() {
  color: nb-color(secondary, brown-500);
}

@mixin nb-color-secondary-brown-300() {
  color: nb-color(secondary, brown-300);
}

@mixin nb-color-secondary-brown-100() {
  color: nb-color(secondary, brown-100);
}

// Base colors
@mixin nb-color-base-dark-900() {
  color: nb-color(base, dark-900);
}

@mixin nb-color-base-dark-700() {
  color: nb-color(base, dark-700);
}

@mixin nb-color-base-dark-500() {
  color: nb-color(base, dark-500);
}

@mixin nb-color-base-dark-300() {
  color: nb-color(base, dark-300);
}

@mixin nb-color-base-dark-100() {
  color: nb-color(base, dark-100);
}

@mixin nb-color-base-white() {
  color: nb-color(base, white);
}

@mixin nb-color-base-black() {
  color: nb-color(base, black);
}
