@import "styles/design-system";

.nb-sub-materials-details-action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;

  .nb-sub-materials-details-action-button:not(:last-of-type) {
    margin-right: nb-rem(10px);
  }
}
