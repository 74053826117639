@import "styles/design-system";

.nb-exercise-constructor-true-or-false-timer-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nb-exercise-constructor-true-or-false-condition,
.nb-exercise-constructor-true-or-false-timer {
  margin-bottom: 0;
}

.nb-exercise-constructor-true-or-false-timer {
  margin-top: nb-rem(10px);
  width: 100%;
}
