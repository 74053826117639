@import "styles/design-system";

.nb-interactive-person-select .nb-interactive-person-select-menu {
  position: relative;
  margin-top: nb-rem(10px);
  margin-bottom: 0;
  border: none;
  box-shadow: none;
  padding: 0;
  background: transparent;
  border-radius: 0;
  z-index: 0;
}
