@import "styles/design-system";

.nb-table-head-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: nb-rem(10px) nb-rem(20px);
}
