@import "styles/design-system";

.nb-exercise-constructor-main {
  position: relative;
  z-index: 2;
  background: nb-color(base, white);
  width: nb-rem(431px);
}

.nb-exercise-constructor-list-holder {
  @include nb-scrollbar;

  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - nb-rem(86px));
}
