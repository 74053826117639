@import "styles/design-system";

.nb-interactive-select .nb-interactive-select-value-container {
  padding: nb-rem(7.75px) nb-rem(10px);

  &--element {
    padding: 0;
  }

  &--with-icon {
    padding-left: nb-rem(5px);
  }
}
