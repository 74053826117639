@import "styles/design-system";

.nb-interactive-info-radio-button-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  &--full {
    width: 100%;
  }
}

.nb-interactive-info-radio-button-label {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
  @include nb-shape(xlarge);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  min-height: nb-rem(41px);
  min-width: nb-rem(160px);
  padding: nb-rem(8px) nb-rem(18px);
  margin: 0;
  width: 100%;
  appearance: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border: solid nb-rem(1px) transparent;
  background: nb-color(secondary, grey-100);
  outline-color: nb-color(primary, orange-900);
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: nb-color(secondary, purple-300);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active:not(:disabled) {
    transition: none;
    opacity: 0.4;
    outline-color: nb-color(primary, orange-900);
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }

  &--success {
    background: nb-color(secondary, green-100);

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, green-100);
    }
  }

  &--error {
    background: nb-color(secondary, error-100);

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, error-100);
    }
  }

  &--bordered {
    border: solid nb-rem(1px) nb-color(secondary, grey-300);

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      border: solid nb-rem(1px) nb-color(secondary, purple-300);
    }

    &.nb-interactive-info-radio-button-label--success {
      border: solid nb-rem(1px) nb-color(secondary, green-500);

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        border: solid nb-rem(1px) nb-color(secondary, green-500);
      }
    }

    &.nb-interactive-info-radio-button-label--error {
      border: solid nb-rem(1px) nb-color(secondary, error-900);

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        border: solid nb-rem(1px) nb-color(secondary, error-900);
      }
    }
  }
}

.nb-interactive-info-radio-button {
  @include nb-visibility-hidden;

  outline-color: nb-color(primary, orange-900);

  &:focus-visible + .nb-interactive-info-radio-button-label {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:disabled + .nb-interactive-info-radio-button-label {
    cursor: default;
    opacity: 0.8;
  }

  &:checked + .nb-interactive-info-radio-button-label {
    background: nb-color(secondary, purple-300);
    border: solid nb-rem(1px) nb-color(secondary, purple-300);

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, purple-300);
      border: solid nb-rem(1px) nb-color(secondary, purple-300);
    }
  }

  &:checked + .nb-interactive-info-radio-button-label--success {
    background: nb-color(secondary, green-500);
    border: solid nb-rem(1px) nb-color(secondary, green-500);

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, green-500);
      border: solid nb-rem(1px) nb-color(secondary, green-500);
    }
  }

  &:checked + .nb-interactive-info-radio-button-label--error {
    background: nb-color(secondary, error-900);
    border: solid nb-rem(1px) nb-color(secondary, error-900);

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, error-900);
      border: solid nb-rem(1px) nb-color(secondary, error-900);
    }
  }
}
