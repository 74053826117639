@import "styles/design-system";

.nb-sub-date-controls-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.nb-sub-date-controls-previous,
.nb-sub-date-controls-picker {
  margin-right: nb-rem(10px);
}

.nb-sub-date-controls-previous {
  svg {
    transform: rotate(90deg);
  }
}

.nb-sub-date-controls-next {
  svg {
    transform: rotate(270deg);
  }
}
