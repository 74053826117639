@import "styles/design-system";

.nb-interactive-tooltip-container {
  position: relative;
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}

.nb-interactive-tooltip-content {
  @include nb-shape;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  margin: 0;
  visibility: hidden;
  width: max-content;
  max-width: nb-rem(308px);
  padding: nb-rem(15px);
  background-color: nb-color(secondary, purple-300);
  transition:
    opacity nb-easing(in) nb-duration(base) nb-delay(base),
    visibility nb-easing(in) nb-duration(base) nb-delay(base);

  // Using CSS variables to create animation with flexible configuration ability
  transform: var(--nb-interactive-tooltip-transform-translate);
  transform-origin: var(--nb-interactive-tooltip-transform-origin);

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &--compact {
    padding: nb-rem(4px) nb-rem(8px);
  }
}

.nb-interactive-tooltip-text {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;

  padding: 0;
  margin: 0;
  white-space: pre-line;
}

.nb-interactive-tooltip-arrow {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-width: 0 nb-rem(5px) nb-rem(5px);
  border-color: transparent transparent nb-color(secondary, purple-300);
  border-style: solid;

  &--visible {
    display: inline-block;
  }

  &--top {
    top: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  &--top-start {
    top: 100%;
    left: 0;
    transform: translateX(100%) rotate(180deg);
  }

  &--top-end {
    top: 100%;
    right: 0;
    transform: translateX(-100%) rotate(180deg);
  }

  &--right {
    top: 50%;
    right: 100%;
    transform-origin: top;
    transform: translateY(-50%) rotate(270deg);
  }

  &--right-start {
    top: 0;
    right: 100%;
    transform-origin: top;
    transform: translateY(200%) rotate(270deg);
  }

  &--right-end {
    bottom: 0;
    right: 100%;
    transform-origin: top;
    transform: translateY(-100%) rotate(270deg);
  }

  &--bottom {
    top: 0;
    left: 50%;
    transform-origin: initial;
    transform: translate(-50%, -100%) rotate(0);
  }

  &--bottom-start {
    top: 0;
    left: 0;
    transform-origin: initial;
    transform: translate(100%, -100%) rotate(0);
  }

  &--bottom-end {
    top: 0;
    right: 0;
    transform-origin: initial;
    transform: translate(-100%, -100%) rotate(0);
  }

  &--left {
    top: 50%;
    left: 100%;
    transform-origin: top;
    transform: translateY(-50%) rotate(90deg);
  }

  &--left-start {
    top: 0;
    left: 100%;
    transform-origin: top;
    transform: translateY(200%) rotate(90deg);
  }

  &--left-end {
    bottom: 0;
    left: 100%;
    transform-origin: top;
    transform: translateY(-100%) rotate(90deg);
  }
}
