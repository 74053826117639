@import "styles/design-system";

.nb-page-admin-container {
  padding: nb-rem(24px) nb-rem(24px);
}

.nb-page-admin-wrapper {
  @include nb-shape;

  background-color: nb-color(base, white);
  width: 100%;
  padding: nb-rem(24px);
}
