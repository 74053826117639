@import "styles/design-system";

.nb-exercise-constructor-list-item-container {
  margin: 0;
  padding: 0;

  &:last-of-type {
    padding-bottom: nb-rem(20px);

    .nb-exercise-constructor-list-item-divider {
      display: none;
    }
  }
}

.nb-exercise-constructor-list-item-button {
  @include nb-shape;

  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
  transition: all nb-easing(in) nb-duration(base) nb-delay(base);
  outline-color: nb-color(primary, orange-900);

  &:hover {
    transform: scale(1.03);
  }

  &:focus-visible {
    outline-offset: nb-rem(5px);
    outline: nb-rem(1px) solid nb-color(primary, orange-900);
  }

  &:active {
    transition: none;
    transform: scale(1);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    transform: scale(1);
  }

  &:active:focus {
    outline-offset: nb-rem(1px);
    outline: 0 solid nb-color(primary, orange-900);
    box-shadow: none;
  }
}

.nb-exercise-constructor-list-item-wrapper {
  @include nb-shape;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: stretch;
  text-align: left;
  width: 100%;
  height: nb-rem(179px);
  padding: nb-rem(20px);
  overflow: hidden;
  background-color: nb-color(secondary, grey-100);
}

.nb-exercise-constructor-list-item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center right 15px;
  background-size: auto;
  z-index: 1;
}

.nb-exercise-constructor-list-item-text {
  position: absolute;
  top: 0;
  left: nb-rem(-9px);
  color: nb-color(secondary, grey-200);
  text-transform: uppercase;
  font-weight: nb-font-weight(bold);
  font-size: nb-rem(68px);
  white-space: nowrap;
  z-index: 0;
}

.nb-exercise-constructor-list-item-title {
  @include nb-text-style-body1(bold);
  @include nb-color-base-dark-900;

  position: relative;
  z-index: 3;
}
