@import "styles/design-system";

.nb-exercise-words-comparing-word-button {
  @include nb-text-style-body2;
  @include nb-color-base-dark-900;
  @include nb-shape(xlarge);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  min-height: nb-rem(41px);
  min-width: nb-rem(160px);
  padding: nb-rem(9px) nb-rem(18px);
  margin: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, grey-300);
  background: nb-color(base, white);
  outline-color: nb-color(primary, orange-900);
  transition:
    box-shadow nb-duration(slow) nb-easing(base) nb-delay(base),
    transform nb-duration(slow) nb-easing(base) nb-delay(base),
    outline-offset nb-duration(slow) nb-easing(base) nb-delay(base),
    outline-color nb-duration(slow) nb-easing(base) nb-delay(base),
    background-color nb-duration(slow) nb-easing(base) nb-delay(base);

  &:hover:not(:disabled) {
    outline: solid 0 transparent;
    background: nb-color(secondary, purple-300);
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, purple-300);
  }

  &:focus-visible:not(:disabled) {
    outline: solid nb-rem(1px) nb-color(primary, orange-900);
    outline-offset: nb-rem(5px);
  }

  &:active:not(:disabled) {
    transition: none;
    outline: solid 0 transparent;
    background: nb-color(secondary, purple-300);
  }

  &:disabled {
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, purple-300);
    cursor: default;
  }

  &--highlight {
    box-shadow: inset 0 0 0 nb-rem(3px) nb-color(secondary, purple-300);
  }

  &--selected {
    background: nb-color(secondary, purple-300);
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, purple-300);
  }

  &--paired,
  &--paired:disabled {
    background: nb-color(secondary, blue-100);
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, blue-300);
  }

  &--correct,
  &--correct:disabled {
    background: nb-color(secondary, green-100);
    box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, green-500);
    cursor: default;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, green-100);
      box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, green-500);
    }
  }

  &--incorrect,
  &--incorrect:disabled {
    background: nb-color(secondary, error-100);
    outline: solid nb-rem(1px) nb-color(secondary, error-900);
    outline-offset: nb-rem(-1px);
    cursor: default;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background: nb-color(secondary, error-100);
      outline: solid nb-rem(1px) nb-color(secondary, error-900);
      outline-offset: nb-rem(-1px);
    }
  }
}
