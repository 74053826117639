@import "styles/design-system";

.nb-lesson-details-homework-content-container {
  @include nb-shape;

  grid-area: lesson-content;
  background-color: nb-color(base, white);
  padding: nb-rem(30px);
  min-width: nb-rem(450px);
}
