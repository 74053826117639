@import "styles/design-system";

.nb-interactive-pair-words-input-word-field,
.nb-interactive-pair-words-input-linked-word-field {
  .nb-interactive-input-holder {
    min-width: nb-rem(123px);
  }

  margin-bottom: 0;
}
