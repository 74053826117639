@import "styles/design-system";

$nb-common-divider-margin-size-xsm: 5px;
$nb-common-divider-margin-size-sm: 10px;
$nb-common-divider-margin-size-md: 15px;
$nb-common-divider-margin-size-l: 20px;
$nb-common-divider-margin-size-xl: 25px;
$nb-common-divider-margin-size-xxl: 30px;

.nb-common-divider {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  height: 1px;
  border-bottom: nb-rem(1px) solid nb-color(secondary, grey-300);

  @at-root #{&}--size-xsm#{&}--margin-top {
    margin-top: nb-rem($nb-common-divider-margin-size-xsm);
  }

  @at-root #{&}--size-xsm#{&}--margin-bottom {
    margin-bottom: nb-rem($nb-common-divider-margin-size-xsm);
  }

  @at-root #{&}--size-sm#{&}--margin-top {
    margin-top: nb-rem($nb-common-divider-margin-size-sm);
  }

  @at-root #{&}--size-sm#{&}--margin-bottom {
    margin-bottom: nb-rem($nb-common-divider-margin-size-sm);
  }

  @at-root #{&}--size-md#{&}--margin-top {
    margin-top: nb-rem($nb-common-divider-margin-size-md);
  }

  @at-root #{&}--size-md#{&}--margin-bottom {
    margin-bottom: nb-rem($nb-common-divider-margin-size-md);
  }

  @at-root #{&}--size-l#{&}--margin-top {
    margin-top: nb-rem($nb-common-divider-margin-size-l);
  }

  @at-root #{&}--size-l#{&}--margin-bottom {
    margin-bottom: nb-rem($nb-common-divider-margin-size-l);
  }

  @at-root #{&}--size-xl#{&}--margin-top {
    margin-top: nb-rem($nb-common-divider-margin-size-xl);
  }

  @at-root #{&}--size-xl#{&}--margin-bottom {
    margin-bottom: nb-rem($nb-common-divider-margin-size-xl);
  }

  @at-root #{&}--size-xxl#{&}--margin-top {
    margin-top: nb-rem($nb-common-divider-margin-size-xxl);
  }

  @at-root #{&}--size-xxl#{&}--margin-bottom {
    margin-bottom: nb-rem($nb-common-divider-margin-size-xxl);
  }

  &--color-grey-100 {
    border-color: nb-color(secondary, grey-100);
  }

  &--color-grey-300 {
    border-color: nb-color(secondary, grey-300);
  }

  &--dashed {
    border: none;
    border-bottom: nb-rem(1px) dashed nb-color(secondary, grey-300);
  }

  &--direction-vertical {
    width: 1px;
    min-height: 100%;
    height: auto;
    border-bottom-width: 0;
    border-left-width: nb-rem(1px);
    border-left-style: solid;
  }
}
