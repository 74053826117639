@import "styles/design-system";

.nb-sub-add-group-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.nb-sub-add-group-first-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(10px);
}

.nb-sub-add-group-avatar {
  margin-right: nb-rem(10px);
}

.nb-sub-add-group-name-input {
  flex-grow: 1;
  margin-bottom: 0;
}
