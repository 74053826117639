@import "styles/design-system";

.nb-interactive-true-or-false-assertion-select .nb-interactive-true-or-false-assertion-select-menu {
  @include nb-shape;

  margin-top: nb-rem(5px);
  border-width: nb-rem(1px);
  border-style: solid;
  border-color: nb-color(secondary, grey-300);
  box-shadow: none;
  background: nb-color(base, white);
  z-index: 5;
}
