@import "styles/design-system";

.nb-interactive-select-portal .nb-interactive-select-option {
  @include nb-text-style-body2;
  @include nb-color-primary-grey-900;

  transition: background-color nb-easing(base) nb-duration(base) nb-delay(base);
  background: nb-color(base, white);
  cursor: pointer;
  padding: nb-rem(8px) nb-rem(10px);

  &:hover {
    background: nb-color(secondary, purple-300);
  }

  &--element {
    padding: 0;
    padding-right: 15px;

    &:hover {
      background: nb-color(secondary, brown-100);
    }
  }

  &--selected {
    background: nb-color(secondary, purple-300);

    &.nb-interactive-select-option--element {
      background: nb-color(secondary, brown-100);
    }
  }
}
