@import "styles/design-system";

.nb-page-component-request-reset-password-form {
  display: flex;
  flex-flow: column nowrap;
}

.nb-page-component-request-reset-password-email {
  margin-bottom: nb-rem(20px);
}

.nb-page-component-request-reset-password-form-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: nb-rem(20px);
}
