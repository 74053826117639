@import "styles/design-system";

.nb-interactive-clue-select .nb-interactive-clue-select-menu-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: center;
  gap: nb-rem(5px);
  padding: nb-rem(5px) 0;
}
