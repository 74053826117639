@import "styles/design-system";

.nb-exercise-constructor-test {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: nb-rem(20px);
}

.nb-exercise-constructor-test-timer-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nb-exercise-constructor-test-condition,
.nb-exercise-constructor-test-timer {
  margin-bottom: 0;
}

.nb-exercise-constructor-test-timer {
  margin-top: nb-rem(10px);
  width: 100%;
}
