@import "styles/design-system";

.nb-lesson-details-sidebar-container {
  grid-area: lesson-sidebar;
  display: grid;
  grid-template:
    [sidebar] "chapters-sidebar" auto
    [sidebar] "homework-sidebar" auto / 1fr;
  gap: nb-rem(20px) 0;
  grid-auto-flow: row;
  justify-content: start;
  align-content: start;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
  min-width: nb-rem(351px);

  &--with-header {
    grid-template:
      [sidebar] "header-sidebar" auto
      [sidebar] "chapters-sidebar" auto
      [sidebar] "homework-sidebar" auto / 1fr;
  }
}
