@import "styles/design-system";

.nb-table-body-row {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: nb-rem(10px) nb-rem(20px);
  transition: background-color nb-easing(in) nb-duration(base) nb-delay(base);
  background-color: nb-color(base, white);
  gap: nb-rem(15px);

  &:not(:last-of-type) {
    margin-bottom: nb-rem(10px);
  }

  &:hover {
    background-color: nb-color(secondary, purple-300);
  }

  &--dragging {
    opacity: 0;
  }
}

.nb-table-body-row-cell-spacer {
  display: flex;
  min-height: nb-rem(50px);
}
