@import "styles/design-system";

.nb-typography-heading-1 {
  @include nb-text-style-heading1;
  @include nb-color-base-dark-900;

  margin: 0;
  padding: 0;

  &--regular {
    @include nb-text-style-heading1(regular);
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}

.nb-typography-heading-2 {
  @include nb-text-style-heading2;
  @include nb-color-base-dark-900;

  margin: 0;
  padding: 0;

  &--regular {
    @include nb-text-style-heading2(regular);
  }

  &--ptmono {
    font-family: nb-font-family(ptmono);
  }
}
