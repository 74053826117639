@import "styles/design-system";

.nb-drag-and-drop-draggable-placeholder {
  @include nb-text-style-body2;
  @include nb-shape(xlarge);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  min-height: nb-rem(41px);
  min-width: nb-rem(160px);
  padding: nb-rem(9px) nb-rem(18px);
  margin: 0;
  appearance: none;
  outline: none;
  border: none;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
  color: transparent;
  background: transparent;
  box-shadow: inset 0 0 0 nb-rem(1px) nb-color(secondary, grey-300);
  transition: box-shadow nb-duration(slow) nb-easing(base) nb-delay(base);
}
