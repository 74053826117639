@import "styles/design-system";

.nb-interactive-select-portal .nb-interactive-select-menu {
  margin-top: -1px;
  margin-bottom: 0;
  border-width: 0 nb-rem(1px) nb-rem(1px);
  border-style: solid;
  border-color: nb-color(secondary, grey-300);
  border-radius: 0 0 nb-rem(6px) nb-rem(6px);
  box-shadow: none;
  background: nb-color(base, white);
  z-index: 5;
  overflow: hidden;
}
