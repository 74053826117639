@import "styles/design-system";

.nb-table-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: auto;

  &--loading {
    min-height: nb-rem(250px);
  }
}

.nb-table-root {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
