@import "styles/design-system";

.nb-sub-group-details-hero {
  @include nb-shape;

  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  padding: nb-rem(20px);
  background-color: nb-color(base, white);
  margin-bottom: nb-rem(10px);
}

.nb-sub-group-details-hero-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nb-sub-group-details-hero-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.nb-sub-group-details-hero-info {
  display: flex;
  flex-flow: column nowrap;
  margin-left: nb-rem(20px);
}

.nb-sub-group-details-hero-title {
  margin-bottom: nb-rem(5px);
}

.nb-sub-group-details-hero-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  .nb-sub-group-details-hero-button:not(:last-of-type) {
    margin-right: nb-rem(15px);
  }
}
