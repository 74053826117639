@import "styles/design-system";

.nb-true-or-false-exercise-carousel-container {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;

  // CSS Grid/Flexbox bug size workaround
  // @see https://github.com/kenwheeler/slick/issues/982
  // @see https://github.com/nolimits4web/swiper/issues/3599
  min-height: 0;
  min-width: 0;
}

.nb-true-or-false-exercise-carousel-button {
  opacity: 1;

  &:disabled {
    &:active {
      @include nb-color-base-dark-900;
    }
  }
}

.nb-true-or-false-exercise-carousel-counter {
  text-align: center;
  margin-bottom: nb-rem(17px);
}
