@import "styles/design-system";

.nb-exercise-constructor-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: nb-rem(20px) calc(nb-rem(30px) - $nb-scrollbar-width) nb-rem(20px) nb-rem(30px);
  margin: 0;
}

.nb-exercise-constructor-list-empty {
  flex-grow: 1;
  text-align: center;
}
