@import "styles/design-system";

.nb-common-avatar {
  display: flex;
  background-color: transparent;

  &--small {
    width: nb-rem(50px);
    height: nb-rem(50px);
  }

  &--medium {
    width: nb-rem(60px);
    height: nb-rem(60px);
  }

  &--large {
    width: nb-rem(79px);
    height: nb-rem(79px);
  }

  &--x-large {
    width: nb-rem(144px);
    height: nb-rem(144px);
  }
}

.nb-common-avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--bordered {
    border: solid nb-border-weight(thin) nb-color(secondary, grey-100);
  }

  &--rect {
    @include nb-shape;
  }

  &--round {
    @include nb-shape(circle);
  }

  &--white {
    background: nb-color(base, white);
  }

  &--blue {
    background: nb-color(secondary, blue-100);
  }

  &--orange {
    background: nb-color(primary, orange-900);
  }

  &--grey {
    background: nb-color(secondary, grey-100);
  }
}

.nb-common-avatar-emoji {
  @include nb-text-style-heading2;
}

.nb-common-avatar-image {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  vertical-align: middle;
  object-fit: cover;
  text-align: center;

  &--rect {
    @include nb-shape;
  }

  &--round {
    @include nb-shape(circle);
  }
}
